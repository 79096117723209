import * as React from 'react'
import { render } from 'react-dom'
import { Provider } from 'mobx-react'
import { AppContainer } from 'react-hot-loader'
import Root from './Root'
import createClient from './apollo/client'
import { store } from './helpers/authentication'

import './index.css'

const client = createClient()

const renderApp = (Component: any) =>
  render(
    <AppContainer>
      <Provider state={store}>
        <Component client={client} />
      </Provider>
    </AppContainer>,
    document.getElementById('root')
  )

renderApp(Root)

if (module.hot) {
  module.hot.accept('./Root', () => {
    const nextRoot = require('./Root').default
    renderApp(nextRoot)
  })
}

import * as React from 'react'
import Button from '../components/Button'
import { AnyFunction } from '../types/AnyFunction'

export type FormButtonsProps = {
  className?: string
  enabled?: boolean
  submitEnabled?: boolean
  onEnable?: AnyFunction
  onCancel?: AnyFunction
  cancelRoute?: string
  submitClass?: string
  submitLabel?: string
  cancelLabel?: string
  enableLabel?: string
}

class FormButtons extends React.Component<FormButtonsProps, {}> {
  render() {
    const {
      className,
      enabled = true,
      submitEnabled = true,
      onEnable,
      onCancel,
      cancelRoute,
      submitClass,
      submitLabel = 'Tallenna',
      cancelLabel = 'Peruuta',
      enableLabel = 'Muokkaa'
    } = this.props

    return (
      <div className={className}>
        {enabled ? (
          <React.Fragment>
            <Button
              key="submit_button"
              className={submitClass}
              type="submit"
              inverted
              disabled={!submitEnabled}>
              {submitLabel}
            </Button>
            {cancelRoute ? (
              <Button type="link" to={cancelRoute}>
                {cancelLabel}
              </Button>
            ) : onCancel ? (
              <Button key="cancel_button" type="button" onClick={onCancel}>
                {cancelLabel}
              </Button>
            ) : null}
          </React.Fragment>
        ) : onEnable ? (
          <Button
            key="enable_button"
            type="button"
            onClick={(e) => {
              e.preventDefault()
              onEnable()
            }}>
            {enableLabel}
          </Button>
        ) : null}
      </div>
    )
  }
}

export default FormButtons

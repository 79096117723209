import * as React from 'react'
import { AnyFunction } from '../types/AnyFunction'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import styledTs from 'styled-components-ts'
import is from 'styled-is'

type ButtonProps = {
  children?: React.ReactNode
  label?: string | React.ReactNode
  onClick?: AnyFunction
  type?: string
  to?: string
  href?: string
  className?: string
  style?: any
  inverted?: boolean
  transparent?: boolean
  disabled?: boolean
  action?: boolean
  leftAligned?: boolean
  small?: boolean
  target?: string
}

type StyledButtonProps = {
  disabled?: boolean
  inverted?: boolean
}

const buttonStyles = css`
  font-size: 0.875em;
  font-family: var(--mark-ot);
  display: block;
  width: 100%;
  background: transparent;
  border: solid 3px #444444;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  color: #444444;
  padding: 1rem 0.25rem;
  margin: 0;
  transition: background 0.1s ease-out;
  cursor: pointer;
  appearance: none;
  border-radius: 2px;
  letter-spacing: 1px;
  text-decoration: none;

  &:hover {
    background-color: black;
    border-color: var(--black);
    color: white;

    svg {
      fill: white;
    }
  }

  svg {
    margin-right: 1rem;
    font-weight: normal;
  }

  ${is('inverted')`
    background-color: black;
    color: white;
  
    &:hover {
      background-color: white;
      color: black;
  
      svg {
        fill: black;
      }
    }
  `};

  ${is('disabled')`
    pointer-events: none;
    background-color: #dedede;
    border-color: #ccc;
    color: var(--grey);
  `};
`

const ButtonNavLink = styledTs<{
  disabled?: boolean
  inverted?: number
  to: string
}>(styled(NavLink))`
  ${buttonStyles}
`

const ButtonAnchor = styledTs<StyledButtonProps>(styled.a)`
  ${buttonStyles};
`

const Button = styledTs<StyledButtonProps>(styled.button)`
  ${buttonStyles}
`

export default ({
  children,
  label = children,
  onClick = () => {},
  type = 'button',
  to = false,
  href = false,
  className,
  style,
  inverted,
  disabled,
  target
}: ButtonProps) => {
  if (to) {
    return (
      <ButtonNavLink
        style={style}
        to={to}
        onClick={onClick}
        disabled={disabled}
        inverted={inverted ? 1 : 0} // Pesky DOM attribute validation
        className={className}>
        {label}
      </ButtonNavLink>
    )
  }

  if (href) {
    return (
      <ButtonAnchor
        style={style}
        href={href}
        disabled={disabled}
        inverted={inverted}
        target={target}
        onClick={onClick}
        className={className}>
        {label}
      </ButtonAnchor>
    )
  }

  return (
    <Button
      disabled={disabled}
      inverted={inverted}
      style={style}
      type={type}
      onClick={onClick}
      className={className}>
      {label}
    </Button>
  )
}

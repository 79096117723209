import * as React from 'react'

import IpBanButton from './IpBanButton'
import withUser from './withUser'

type Props = {
  disabled?: boolean
  userId?: string
  user: {
    signupIpAddress: string
  }
  userLoading: boolean
}

const UserIpBanButton = ({ disabled, user, userLoading, ...rest }: Props) => (
  <IpBanButton
    disabled={disabled || userLoading}
    ipAddress={user && user.signupIpAddress}
    {...rest}
  />
)

export default withUser(UserIpBanButton)

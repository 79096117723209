import * as React from 'react'
import styled, { keyframes, css } from 'styled-components'
import styledTs from 'styled-components-ts'
import { switchProp } from 'styled-tools'
import * as get from 'lodash/get'

import serverErrorStrings from '../strings/serverError'

const loadingAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(359deg);
  }
`

type ContainerProps = {
  state?: string
}

const LoadingContainer = styledTs<ContainerProps>(styled.div)`
  color: var(--green);
  display: none;
  padding: 0 0 1.5rem;
  margin: 0;
  text-align: center;

  ${switchProp('state', {
    error: css`
      display: block !important;
      color: var(--red);
      font-weight: bold;
    `,
    loading: css`
      display: block;
    `,
    doneLoading: css`
      display: block;
    `
  })};
`

const DoneCheck = styled.span`
  width: 8px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  border-right: 2px solid var(--green);
  border-bottom: 2px solid var(--green);
  position: relative;
  margin-left: 0.25rem;
  margin-right: 0.55rem;
  margin-top: -7px; /* Fine-tuning */
  transform: rotate(40deg);
`

const LoadingCircle = styled.span`
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid var(--green);
  border-right: 2px solid transparent;
  border-radius: 50%;
  position: relative;
  margin-right: 0.75rem;
  margin-top: -3px; /* Fine-tuning */
  animation: ${loadingAnimation} 1s ease-out infinite;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(60deg);
    content: '';
    display: block;
    border: 2px solid transparent;
    border-right-color: var(--green);
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
`

const LoadingLabel = styled.span`
  font-weight: bolder;
  display: inline;
`

const enum LoadingStates {
  unset = '',
  notLoading = 'notLoading',
  loading = 'loading',
  loadingDone = 'doneLoading',
  error = 'error'
}

type LoadingState = {
  loading: LoadingStates
}

type LoadingProps = {
  loading?: boolean
  error?: any
  doneLabel?: string | React.ReactNode
  label?: string | React.ReactNode
  className?: string
  style?: any
  inline?: boolean
}

class Loading extends React.Component<LoadingProps, LoadingState> {
  static getDerivedStateFromProps(
    { loading: nextLoading, error = false },
    { loading }
  ) {
    if (loading === LoadingStates.unset) {
      return {
        loading: nextLoading ? LoadingStates.loading : LoadingStates.notLoading
      }
    }

    const loadingState = loading === LoadingStates.loading

    if (nextLoading === loadingState) {
      return null
    }

    if (!!error) {
      return {
        loading: LoadingStates.error
      }
    }

    if (loadingState && !nextLoading) {
      // When loading completes. A timeout will set it to notLoading.
      return {
        loading: LoadingStates.loadingDone
      }
    } else if (!loadingState && nextLoading) {
      // When loading starts
      return {
        loading: LoadingStates.loading
      }
    }

    return null
  }

  state = {
    loading: LoadingStates.unset // start empty
  }

  private _isMounted: boolean = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate() {
    const { loading } = this.state

    if (loading === LoadingStates.loadingDone) {
      // Hide the loading indicator after 3 seconds
      setTimeout(() => {
        // Guard against setting state on an unmounted component.
        if (!this._isMounted) {
          return
        }

        this.setState({
          loading: LoadingStates.notLoading
        })
      }, 3000)
    }
  }

  render() {
    const {
      label = 'Tallennetaan',
      doneLabel = 'Tallennettu',
      style,
      className,
      error
    } = this.props

    const { loading } = this.state

    const message = get(error, 'message', error)
    const graphQLError =
      typeof message === 'string'
        ? message.replace('GraphQL error: ', '')
        : undefined
    const messageStr =
      serverErrorStrings[graphQLError] ||
      graphQLError ||
      'Virhetilanne. Yritä uudelleen.'

    return (
      <LoadingContainer
        className={`${className} loading`}
        state={loading}
        style={style}>
        {loading === LoadingStates.loadingDone && (
          <>
            <DoneCheck />
            <LoadingLabel>{doneLabel}</LoadingLabel>
          </>
        )}
        {loading === LoadingStates.loading && (
          <>
            <LoadingCircle />
            <LoadingLabel>{label}</LoadingLabel>
          </>
        )}
        {loading === LoadingStates.error && (
          <span className="error">{messageStr}</span>
        )}
      </LoadingContainer>
    )
  }
}

export default Loading

import * as React from 'react'

import adminDeleteProfileMutation from '../queries/adminDeleteProfileMutation'
import { Mutation } from '../apollo/Mutation'

const withAdminDeleteProfile = (WrappedComponent) => ({ ...rest }) => (
  <Mutation
    mutation={adminDeleteProfileMutation}
    component={({ mutationResult, mutationError, mutationLoading, mutator }) => (
      <WrappedComponent
        adminDeleteProfile={(id) => mutator({ variables: { id } })}
        adminDeleteProfileResult={mutationResult}
        adminDeleteProfileError={mutationError}
        adminDeleteProfileLoading={mutationLoading}
        {...rest}
      />
    )}
  />
)

export default withAdminDeleteProfile

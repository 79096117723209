import * as React from 'react'
import styled from 'styled-components'
import styledTs from 'styled-components-ts'
import * as truncate from 'lodash/truncate'
import is from 'styled-is'
import RichText from './RichText'
import { faComments, faTrash } from '@fortawesome/fontawesome-free-solid'
import * as get from 'lodash/get'
import { AnyFunction } from '../types/AnyFunction'
import ReportTarget from './ReportTarget'
import { withApollo, compose } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import createAction from '../helpers/createAction'
import parseRaw from '../helpers/parseRaw'
import DeleteModeratedEntityModal from './DeleteModeratedEntityModal'

const { REACT_APP_MAIN_SITE_URL } = process.env

const Title = styledTs<{ bold: boolean }>(styled.h3)`
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  line-height: 1.33;
  color: var(--black);

  ${is('bold')`
    font-weight: bold;
  `}
`

const ReportComment = styled(RichText)`
  line-height: 1.33;
`

type Props = {
  refetchReports: AnyFunction
  markAsVisited: AnyFunction
  isUnseen: boolean
  report: any
  client?: ApolloClient<any>
}

type State = {
  deletingReply?: object
}

const enhance = compose(withApollo)

@enhance
class Reply extends React.Component<Props, State> {
  state = {
    deletingReply: undefined
  }

  delete = () => {
    const {
      report: { target },
      client
    } = this.props

    if (!target) {
      return
    }

    this.setState({
      deletingReply: {
        id: target.id,
        title: target.body.plain
      }
    })
  }

  createModerationActions = (confirmModal) => [
    ...(this.props.report.target
      ? [
          createAction(
            'Näytä keskustelu',
            faComments,
            `${REACT_APP_MAIN_SITE_URL}/keskustelu/${get(
              this,
              'props.report.target.thread.slug',
              ''
            )}#${get(this, 'props.report.target.id', '')}`
          ),
          createAction('Poista viesti', faTrash, () => this.delete())
        ]
      : [])
  ]

  render() {
    const { report, isUnseen, ...props } = this.props
    const { deletingReply } = this.state
    const body = report.target ? report.target.body : null

    const parsedBody = body ? parseRaw(body.raw) : null

    return (
      <>
        <ReportTarget
          {...props}
          report={report}
          createModerationActions={this.createModerationActions}>
          {(displayState) =>
            displayState === 'open' ? (
              parsedBody ? (
                <ReportComment html={parsedBody} />
              ) : (
                <i>(viesti on poistettu)</i>
              )
            ) : parsedBody ? (
              <Title bold={isUnseen}>{truncate(parsedBody, { length: 105 })}</Title>
            ) : (
              <i>(viesti on poistettu)</i>
            )
          }
        </ReportTarget>
        <DeleteModeratedEntityModal
          type="reply"
          id={deletingReply ? deletingReply.id : undefined}
          title={deletingReply ? deletingReply.title : undefined}
          onSuccess={() => {
            this.setState({
              deletingReply: undefined
            })
            props.refetchReports()
          }}
          onCancel={() => {
            this.setState({
              deletingReply: undefined
            })
          }}
        />
      </>
    )
  }
}

export default Reply

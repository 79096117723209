import * as React from 'react'
import styled from 'styled-components'
import placeholderAvatar from '../img/avatar.png'
import * as truncate from 'lodash/truncate'
import { Collapse } from 'react-collapse'
import { presets } from 'react-motion'
import ToggleButton from './ToggleButton'
import Button from './Button'
import FontAwesome from '@fortawesome/react-fontawesome'
import { faBan, faEye, faPencilAlt } from '@fortawesome/fontawesome-free-solid'
import BanButton from './BanButton'
import UsernameDeleteProfileButton from './UsernameDeleteProfileButton'
import UserInfo from '../components/UserInfo'
import UserIpBanButton from './UserIpBanButton'
import Optimistic from './Optimistic'
import { compose, withApollo } from 'react-apollo'
import { banUser, unbanUser } from '../queries/banUser'
import { routes } from '../routes'
import { ConfirmModalContext } from '../helpers/ConfirmModal'
import { getProfileUrl } from '../helpers/profiles'

const { REACT_APP_MAIN_SITE_URL, REACT_APP_SITE } = process.env

const UserItem = styled.div`
  position: relative;
  padding: 0.25rem 1.111rem;

  &:nth-child(even) {
    background: var(--silver);
  }
`

const UserContent = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr 5rem;
  align-items: center;
`

const Avatar = styled.img`
  width: 100%;
`

const TextContent = styled.a`
  padding: 0.5rem 0.75rem;
  color: var(--black);
  text-decoration: none;
`

const Actions = styled.div`
  padding: 2rem 1.25rem;
  max-width: 20rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  > button,
  > a {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const BannedIcon = styled(FontAwesome)`
  color: var(--salmon);
  margin-left: 1rem;
`

const StyledToggleButton = styled(ToggleButton)`
  top: 1.2rem;
`

const UserInfoContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
`

type displayStateType = 'open' | 'closed'

type State = {
  displayState: displayStateType
  banDuration: string
}

const enhance = compose(
  Optimistic(({ identifier }) => identifier, 'isBanned'),
  withApollo
)

@enhance
class User extends React.Component<any, State> {
  state: State = {
    displayState: 'closed',
    banDuration: ''
  }

  toggleDisplayState = (
    setTo: displayStateType = this.state.displayState === 'open' ? 'closed' : 'open'
  ) => {
    this.setState({
      displayState: setTo
    })
  }

  ban = (banDuration) => {
    const { set, client, identifier } = this.props

    set(true)

    client.mutate({
      mutation: banUser,
      variables: { identifier, banDuration }
    })
  }

  unban = () => {
    const { set, client, identifier } = this.props
    set(false)

    client
      .mutate({
        mutation: unbanUser,
        variables: { identifier }
      })
      .then(() => {
        this.setState({
          banDuration: ''
        })
      })
  }

  onSelectBanDuration = (value) => {
    this.setState({
      banDuration: value
    })

    this.ban(value)
  }

  render() {
    const { username = '', userIdentifier, profileImage = '', isBanned } = this.props
    const { displayState, banDuration } = this.state

    const profileUrl = getProfileUrl(username)

    return (
      <ConfirmModalContext.Consumer>
        {(confirmWithModal) => (
          <UserItem>
            <UserContent>
              <Avatar src={profileImage || placeholderAvatar} />
              <TextContent target="_blank" href={profileUrl}>
                <strong>{truncate(username, { length: 22 })}</strong>
                {isBanned && <BannedIcon icon={faBan} />}
              </TextContent>
              <StyledToggleButton
                right
                state={displayState}
                onClick={() => this.toggleDisplayState()}
              />
            </UserContent>
            <Collapse
              isOpened={displayState === 'open'}
              springConfig={presets.stiff}>
              <UserInfoContainer>
                <UserInfo userId={userIdentifier} />
              </UserInfoContainer>
              <Actions>
                <Button href={profileUrl} target="_blank">
                  <FontAwesome icon={faEye} />
                  Avaa julkinen profiili
                </Button>
                <Button to={routes.userDetails(username)}>
                  <FontAwesome icon={faPencilAlt} />
                  Muokkaa
                </Button>
                <UsernameDeleteProfileButton
                  confirmWithModal={confirmWithModal}
                  username={username}
                />
                {REACT_APP_SITE === 'demi' && (
                  <BanButton
                    onClick={this.unban}
                    value={banDuration}
                    onChange={this.onSelectBanDuration}
                    isBanned={isBanned}
                  />
                )}
                {REACT_APP_SITE === 'demi' && (
                  <UserIpBanButton
                    confirmWithModal={confirmWithModal}
                    userId={userIdentifier}
                  />
                )}
              </Actions>
            </Collapse>
          </UserItem>
        )}
      </ConfirmModalContext.Consumer>
    )
  }
}

export default User

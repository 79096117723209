import classnames from 'classnames'
import FontAwesome from '@fortawesome/react-fontawesome'
import { faCircle, faDotCircle } from '@fortawesome/fontawesome-free-regular'
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import * as React from 'react'
import styled, { keyframes } from 'styled-components'

const rotateAnim = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const IconContainer = styled.span`
  margin-right: 5px;
  &.spin > * {
    animation: ${rotateAnim} 2s linear infinite;
  }
  &.disabled {
    color: var(--grey);
  }
`

type RadioButtonProps = {
  id?: string
  name?: string
  value?: string
  checked: boolean
  disabled?: boolean
  loading?: boolean
  onChange: Function
  children?: React.ReactNode
  label?: React.ReactNode
}

class RadioButtonInput extends React.Component<RadioButtonProps, {}> {
  render() {
    const {
      onChange,
      value,
      children,
      label = children,
      name,
      checked,
      disabled,
      loading,
      id = `radio_${name}_${value}`
    } = this.props

    return (
      <>
        <div>
          <div>
            <input
              disabled={disabled}
              type="radio"
              id={id}
              value={value}
              name={name}
              onChange={(e) => onChange(e)}
              checked={checked}
              style={{
                display: 'none'
              }}
            />
            <label htmlFor={id}>
              <IconContainer
                className={classnames(loading && 'spin', disabled && 'disabled')}>
                {loading ? (
                  <FontAwesome icon={faSpinner} />
                ) : checked ? (
                  <FontAwesome icon={faDotCircle} />
                ) : (
                  <FontAwesome icon={faCircle} spin />
                )}
              </IconContainer>
              {label}
            </label>
          </div>
        </div>
      </>
    )
  }
}

export default RadioButtonInput

import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
// Run the scripts/getTypes.js script to generate this file.
import introspectionQueryResultData from './fragmentTypes.json'
import { ApolloClient } from 'apollo-client'
import { createApolloFetch, FetchResult } from 'apollo-fetch'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import * as get from 'lodash/get'
import { setAuthTokens } from '../helpers/authentication'
import { AuthenticationState } from '../types/AuthenticationState'

const { REACT_APP_TOKEN_STORAGE_KEY, REACT_APP_API_URL } = process.env

// Apollo-fetch does NOT support graphql-tag
const refreshAccessToken = `
  mutation refreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`

let refreshTokenRequest: Promise<FetchResult> | boolean = false

const authLink = setContext(async (_, { headers = {} }) => {
  const userAuth = localStorage.getItem(REACT_APP_TOKEN_STORAGE_KEY)

  const { accessToken, expiryTime, refreshToken }: AuthenticationState = userAuth
    ? JSON.parse(userAuth)
    : { accessToken: undefined, refreshToken: '', expiryTime: 0 }

  const isExpired = Math.round(Date.now() / 1000) > expiryTime

  if (accessToken && !isExpired) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`
      }
    }
  } else if (isExpired && refreshToken) {
    const apolloFetch = createApolloFetch({ uri: REACT_APP_API_URL })

    refreshTokenRequest =
      refreshTokenRequest instanceof Promise
        ? refreshTokenRequest
        : apolloFetch({
            query: refreshAccessToken,
            variables: { refreshToken }
          })

    const newTokens = await refreshTokenRequest
    refreshTokenRequest = false

    const { accessToken: newAccessToken } = setAuthTokens(
      get(newTokens, 'data.refreshAccessToken')
    )

    if (newAccessToken) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${newAccessToken}`
        }
      }
    }
  }

  return headers
})

export default () => {
  const httpLink = new HttpLink({
    uri: REACT_APP_API_URL
  })

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  })

  const cache = new InMemoryCache({ fragmentMatcher })

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache
  })

  return client
}

import * as React from 'react'

import adminBanIpAddressMutation from '../queries/adminBanIpAddressMutation'
import adminBannedIpAddressesQuery from '../queries/adminBannedIpAddressesQuery'
import { Mutation } from '../apollo/Mutation'

const withAdminBanIpAddress = (WrappedComponent) => ({ ...rest }) => (
  <Mutation
    mutation={adminBanIpAddressMutation}
    refetchQueries={[{ query: adminBannedIpAddressesQuery }]}
    component={({
      mutationResult: { adminBanIpAddress },
      mutationError,
      mutationLoading,
      mutator
    }) => (
      <WrappedComponent
        adminBanIpAddress={(ipAddress) => mutator({ variables: { ipAddress } })}
        adminBanIpAddressResult={adminBanIpAddress}
        adminBanIpAddressError={mutationError}
        adminBanIpAddressLoading={mutationLoading}
        {...rest}
      />
    )}
  />
)

export default withAdminBanIpAddress

export default {
  thread: [
    'halusit poistaa oman keskustelusi',
    'keskustelussa kiusataan tai häiriköidään muita',
    'keskustelu sisältää rasismia, vihapuhetta tai propagandaa',
    'keskustelussa uhataan itsetuhoisuudella tai kuvaillaan haitallisesti itsetuhoista käytöstä',
    'keskustelussa ihaillaan tai kuvaillaan haitallisesti päihteiden käyttöä',
    'keskustelussa ihaillaan tai kuvaillaan haitallisesti syömishäiriöitä tai laihdutusta',
    'keskustelu sisältää pornoa',
    'keskustelu sisältää kaupallista mainostusta tai muuta ilmoittelua',
    'keskustelu rikkoo tekijänoikeuksia',
    'keskustelu on hyvän maun vastainen',
    'keskustelussa on muuta lainvastaista sisältöä'
  ],
  reply: [
    'halusit poistaa oman viestisi',
    'kiusaat tai häiriköit muita',
    'viestisi sisältää rasismia, vihapuhetta tai propagandaa',
    'viestissä uhataan itsetuhoisuudella tai kuvaillaan haitallisesti itsetuhoista käytöstä',
    'viestisi ihannoi tai kuvailee haitallisesti päihteiden käyttöä',
    'viestisi ihannoi tai kuvailee haitallisesti syömishäiriöitä tai laihdutusta',
    'viestisi sisältää pornoa',
    'viestisi sisältää kaupallista mainostusta tai muuta ilmoittelua',
    'viestisi rikkoo tekijänoikeuksia',
    'viestisi on hyvän maun vastainen',
    'viestissä on muuta lainvastaista sisältöä'
  ]
}

import gql from 'graphql-tag'

const { REACT_APP_SITE } = process.env

const mutationName = ((site) => {
  switch (site) {
    case 'demi':
      return 'adminDeleteDemiProfile'
    case 'lily':
      return 'adminDeleteLilyProfile'
    default:
      return null
  }
})(REACT_APP_SITE)

export default gql`
  mutation adminDeleteProfile($id: ID!) {
    adminDeleteProfile: ${mutationName}(id: $id) {
      success,
      errorCode,
      errorMessage
    }
  }
`

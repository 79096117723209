import * as React from 'react'
import * as get from 'lodash/get'
import NodeList from '../components/NodeList'
import Thread from '../components/Thread'
import { AnyFunction } from '../types/AnyFunction'
import { PageInfoFieldsFragment } from '../queries/PageInfoFields'
import { ReportFieldsFragment } from '../queries/ReportFields'
import gql from 'graphql-tag'
import { query } from '../apollo/Query'
import { ThreadFieldsFragment } from '../queries/ThreadFields'
import { compose } from 'react-apollo'
import { ReplyFieldsFragment } from '../queries/ReplyFields'
import Reply from '../components/Reply'
import ReportedUser from '../components/ReportedUser'
import { DemiProfileFieldsFragment } from '../queries/ProfileFields'

type Props = {
  queryResult?: any
  fetchMore?: AnyFunction
  visitedItems?: string[]
  markAsVisited?: AnyFunction
  refetch?: AnyFunction
}

const getReports = gql`
  query reports(
    $cursor: String = null
    $perPage: Int = 5
    $targetType: TargetType!
  ) {
    reports(targetType: $targetType, first: $perPage, after: $cursor) {
      pageInfo {
        ...pageInfoFields
      }
      edges {
        node {
          ...ReportFields
          target {
            ... on Thread {
              ...ThreadFields
            }
            ... on Reply {
              ...ReplyFields
            }
            ... on DemiProfile {
              ...DemiProfileFragment
            }
          }
        }
        cursor
      }
    }
  }
  ${ThreadFieldsFragment}
  ${ReplyFieldsFragment}
  ${PageInfoFieldsFragment}
  ${ReportFieldsFragment}
  ${DemiProfileFieldsFragment}
`

const enhance = compose(query(getReports))

export default enhance(
  ({
    queryResult: { reports: data },
    fetchMore,
    visitedItems,
    markAsVisited,
    refetch
  }: Props) => {
    const edges = get(data, 'edges', [])

    return edges.length > 0 ? (
      <NodeList
        fetchMore={fetchMore}
        data={edges}
        renderItem={(props) => {
          const isUnseen = visitedItems.indexOf(props.id) === -1
          const markSeen = () => markAsVisited(props.id)
          let Component = null

          switch (props.targetType) {
            case 'Thread':
              Component = Thread
              break
            case 'Reply':
              Component = Reply
              break
            case 'DemiProfile':
              Component = ReportedUser
              break
          }

          return Component !== null ? (
            <Component
              refetchReports={refetch}
              markAsVisited={markSeen}
              isUnseen={isUnseen}
              report={props}
            />
          ) : null
        }}
      />
    ) : (
      <p>Ei ilmiantoja.</p>
    )
  }
)

import * as React from 'react'
import styled from 'styled-components'

const Layout = styled.div`
  max-width: 41.111rem;
  width: 100%;
  margin: 0 auto;
`

export default ({ children, ...props }) => <Layout {...props}>{children}</Layout>

import * as React from 'react'
import { observer } from 'mobx-react'
import Button from './Button'
import styled from 'styled-components'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/fontawesome-free-solid'
import { clearAuthTokens } from '../helpers/authentication'
import { routes } from '../routes'

const NavButton = styled(Button)`
  padding: 0.25rem 0rem;
  font-size: 1rem;
  text-align: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border: 0;

  @media (min-width: 620px) {
    font-size: 1.25rem;
    margin-right: 0.25rem;
    margin-left: 1rem;
  }

  svg {
    margin: 0 0.5rem;
  }

  &:hover {
    background: black;

    svg {
      color: white;
    }
  }
`

export default observer(() => (
  <NavButton inverted to={routes.login()} onClick={() => clearAuthTokens()}>
    <FontAwesomeIcon icon={faSignOutAlt} />
  </NavButton>
))

import gql from 'graphql-tag'
import { PageInfoFieldsFragment } from './PageInfoFields'

export const ThreadFieldsFragment = gql`
  fragment ThreadFields on Thread {
    id
    type
    title
    author
    createdAt
    numReplies
    url
  }
  ${PageInfoFieldsFragment}
`

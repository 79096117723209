import * as React from 'react'

import { Query } from '../apollo/Query'
import userQuery from '../queries/userQuery'

export type Props = {
  user?: {
    id: string
    email?: string
    phoneNumber?: string
    signupIpAddress?: string
    demiProfile?: {
      id: string
      username: string
      partner?: {
        id: string
        name: string
      }
    }
    lilyProfile?: {
      id: string
      username: string
    }
    groups: Array<{
      id: string
      name: string
    }>
  }
  userError?: Error
  userLoading: boolean
  refetchUser: Function
}

const withUser = (WrappedComponent) => ({ userId, ...rest }) => (
  <Query
    query={userQuery}
    variables={{
      id: userId
    }}
    skip={!userId}
    showWhileLoading={true}
    component={({ queryResult: { user }, queryError, queryLoading, refetch }) => {
      const props: Props = {
        user,
        userError: queryError,
        userLoading: queryLoading && !!userId,
        refetchUser: refetch
      }
      return <WrappedComponent {...props} {...rest} />
    }}
  />
)

export default withUser

import * as React from 'react'
import styled from 'styled-components'
import ListHeader from '../components/ListHeader'
import Users from '../views/Users'
import FontAwesome from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/fontawesome-free-solid'
import Input from '../forms/Input'

const ListSection = styled.div`
  margin-bottom: 2rem;
`

const SearchWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 2rem;
  padding: 0 1.1rem;
`

const SearchInput = styled(Input)`
  width: 100%;
`

const Icon = styled(FontAwesome)`
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  color: #aaa;
`

type State = {
  searchTerm: string
  searchInput: string
}

class UserSearch extends React.Component<any, State> {
  state: State = {
    searchTerm: '',
    searchInput: ''
  }

  searchTimeout: number = 0

  setSearchTerm = (e) => {
    this.setState({
      searchInput: e.target.value
    })
  }

  componentDidUpdate() {
    const { searchTerm, searchInput } = this.state

    if (searchInput.length > 2 && searchInput !== searchTerm) {
      window.clearTimeout(this.searchTimeout)

      this.searchTimeout = window.setTimeout(() => {
        this.setState({ searchTerm: searchInput })
      }, 300)
    } else if (searchInput.length < 3 && searchTerm.length > 2) {
      this.setState({ searchTerm: '' })
    }
  }

  render() {
    const { searchTerm, searchInput } = this.state

    return (
      <div>
        <ListSection>
          <ListHeader heading="Käyttäjähaku" />
          <SearchWrapper>
            <SearchInput
              type="text"
              name="user-search-terms"
              value={searchInput}
              onChange={this.setSearchTerm}
            />
            <Icon icon={faSearch} />
          </SearchWrapper>
          <Users variables={{ cursor: null, query: searchTerm }} />
        </ListSection>
      </div>
    )
  }
}

export default UserSearch

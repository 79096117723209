import * as React from 'react'
import styled from 'styled-components'

import ErrorDetails from './ErrorDetails'
import LoadingSpinner from './LoadingSpinner'
import withUser from './withUser'

type Props = {
  user: {
    email?: string
    phoneNumber?: string
    signupIpAddress?: string
    demiProfile?: {
      username: string
      partner?: {
        name
      }
    }
    lilyProfile?: {
      username: string
    }
    groups: Array<{
      id: string
      name: string
    }>
  }
  userLoading: boolean
  userError?: Error
}

const Container = styled.div``

const DescriptionList = styled.dl`
  margin: -5px;

  & > dt {
    box-sizing: border-box;
    float: left;
    clear: left;
    width: 30%;
    padding: 5px;
    text-align: left;
    font-weight: bold;
  }
  & > dd {
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    padding: 5px;
    text-align: right;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
  }
`

function UserInfo({ user, userError, userLoading }: Props) {
  if (userLoading) {
    return renderComponent(
      <>
        <LoadingSpinner /> Ladataan...
      </>
    )
  }
  if (userError) {
    return renderComponent(<ErrorDetails error={userError} />)
  }
  if (!user) {
    return null
  }
  return (
    <Container>
      <DescriptionList>
        {user.email && (
          <>
            <dt>Sähköpostiosoite</dt>
            <dd>{user.email}</dd>
          </>
        )}
        {user.phoneNumber && (
          <>
            <dt>Puhelinnumero</dt>
            <dd>{user.phoneNumber}</dd>
          </>
        )}
        {user.signupIpAddress && (
          <>
            <dt>IP-osoite</dt>
            <dd>{user.signupIpAddress}</dd>
          </>
        )}
        {user.groups &&
          user.groups.length > 0 && (
            <>
              <dt>Ryhmät</dt>
              <dd>{user.groups.map(({ name }) => name).join(', ')}</dd>
            </>
          )}
        {user.demiProfile && (
          <>
            <dt>Demi-profiili</dt>
            <dd>{user.demiProfile.username}</dd>
          </>
        )}
        {user.demiProfile &&
          user.demiProfile.partner && (
            <>
              <dt>Demi-partneri</dt>
              <dd>{user.demiProfile.partner.name}</dd>
            </>
          )}
        {user.lilyProfile && (
          <>
            <dt>Lily-profiili</dt>
            <dd>{user.lilyProfile.username}</dd>
          </>
        )}
      </DescriptionList>
    </Container>
  )

  function renderComponent(content) {
    return <Container>{content}</Container>
  }
}

export default withUser(UserInfo)

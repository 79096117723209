import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Redirect } from 'react-router'
import LoginForm from '../forms/LoginForm'
import { routes } from '../routes'

type Props = {
  state?: any
  fieldClass?: string
  showPasswordLink?: boolean
}

@inject('state')
@observer
class LoginWidget extends React.Component<Props, any> {
  static defaultProps = {
    showPasswordLink: true
  }

  render() {
    const { state, fieldClass, showPasswordLink } = this.props

    return state.isLoggedIn ? (
      <Redirect to={routes.dashboard()} />
    ) : (
      <LoginForm passwordLink={showPasswordLink} fieldClass={fieldClass} />
    )
  }
}

export default LoginWidget

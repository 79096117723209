import { mutate } from '../apollo/Mutation'
import * as React from 'react'
import styled from 'styled-components'

import adminUpdateLilyProfileMutation from '../queries/adminUpdateLilyProfileMutation'
import adminUpdateDemiProfileMutation from '../queries/adminUpdateDemiProfileMutation'
import Button from './Button'

const SITE = process.env.REACT_APP_SITE

type Props = {
  profile: any
  confirmWithModal: Function
  onCompleted: Function
  mutator?: Function
}
type State = {
  newBio: string
}

const ActionButtonsDiv = styled.div`
  margin-top: 30px;
`

const ActionButton = styled(Button)`
  margin-top: 5px;
`

const BioTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
`

const BioPre = styled.pre`
  white-space: pre-wrap;
`

const updateProfileMutation =
  (SITE === 'demi' && adminUpdateDemiProfileMutation) ||
  (SITE === 'lily' && adminUpdateLilyProfileMutation) ||
  null

@mutate(updateProfileMutation)
class ProfileBioAdministration extends React.Component<Props, State> {
  state = {
    newBio: this.props.profile.bio
  }

  submit = () => {
    const { profile, confirmWithModal, onCompleted, mutator } = this.props
    const { newBio } = this.state

    confirmWithModal(
      'muokata kuvausta?',
      <>
        Olet asettamassa kuvausta käyttäjälle <b>{profile.username}</b>. Uusi kuvaus
        on:
        <BioPre>{newBio}</BioPre>
      </>,
      () => {
        mutator({
          variables: {
            id: profile.id,
            profile: {
              bio: newBio
            }
          }
        }).then(onCompleted)
      }
    )
  }

  cancel = () => {
    const { profile } = this.props

    this.setState({
      newBio: profile.bio
    })
  }

  render() {
    const { newBio } = this.state
    const { profile } = this.props

    const bioChanged = newBio !== profile.bio

    return (
      <>
        {profile.bio ? (
          <BioTextArea
            value={newBio}
            onChange={({ target: { value } }) => {
              this.setState({
                newBio: value
              })
            }}
          />
        ) : (
          <div>
            <i>Käyttäjällä ei ole kuvausta.</i>
          </div>
        )}
        {bioChanged && (
          <ActionButtonsDiv>
            <ActionButton onClick={this.cancel}>Peruuta</ActionButton>
            <ActionButton onClick={this.submit}>Tallenna</ActionButton>
          </ActionButtonsDiv>
        )}
      </>
    )
  }
}

export default ProfileBioAdministration

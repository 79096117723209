import * as React from 'react'
import FontAwesome from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/fontawesome-free-regular'
import styled from 'styled-components'

const CommentsDisplay = styled.span`
  margin-left: 1rem;
  font-size: 14px;
  font-weight: normal;
  color: #444;
  vertical-align: baseline;
`

export default ({ count }) => (
  <CommentsDisplay>
    <FontAwesome icon={faComment} /> {count}
  </CommentsDisplay>
)

import * as React from 'react'
import { mutate } from '../apollo/Mutation'
import Loading from '../components/Loading'
import { FormProps } from '../types/FormProps'
import Field from '../forms/Field'
import * as get from 'lodash/get'
import gql from 'graphql-tag'
import styled from 'styled-components'
import FormButtons from '../components/FormButtons'
import { observer } from 'mobx-react'
import { setAuthTokens } from '../helpers/authentication'
import { identity } from '../helpers/identity'

type LoginFormState = {
  username: string
  password: string
}

interface LoginFormProps extends FormProps {
  onLoginError?: Function
  onLoginSuccess?: Function
  fieldClass?: string
  passwordLink?: boolean
}

const authenticateUser = gql`
  mutation adminLogin($username: String!, $password: String!) {
    adminLogin(username: $username, password: $password) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`

const Form = styled.form``

@mutate(authenticateUser)
@observer
class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  static defaultProps = {
    passwordLink: true,
    onLoginSuccess: identity
  }

  state: LoginFormState = {
    username: '',
    password: ''
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: get(e, 'target.value', e)
    })
  }

  onLoggedIn = () => {
    const { mutationResult, onLoginSuccess }: LoginFormProps = this.props

    setAuthTokens(mutationResult)
    onLoginSuccess(mutationResult)
  }

  componentDidUpdate() {
    const { mutationResult, mutationError } = this.props

    if (mutationResult && !mutationError) {
      this.onLoggedIn()
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { mutator } = this.props
    const { username, password } = this.state

    mutator({
      variables: { username, password }
    })
  }

  render() {
    const { username, password } = this.state
    const { mutationLoading, mutationError, fieldClass } = this.props

    return (
      <Form onSubmit={this.onSubmit}>
        <Field
          className={fieldClass}
          label="Käyttäjätunnus"
          autoComplete="username"
          type="text"
          name="username"
          id="username"
          onChange={this.setFormState('username')}
          value={username}
          placeholder="Sähköpostiosoite tai puhelinnumero"
        />
        <Field
          className={fieldClass}
          label="Salasana"
          autoComplete="current-password"
          onChange={this.setFormState('password')}
          value={password}
          type="password"
          name="password"
          id="password"
        />
        <Loading
          error={mutationError}
          label="Kirjaudutaan sisään"
          doneLabel="Kirjautuminen onnistui"
          loading={mutationLoading}
        />
        <FormButtons submitLabel="Kirjaudu sisään" />
      </Form>
    )
  }
}

export default LoginForm

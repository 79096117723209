import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Login from './pages/Login'
import ProtectedRoute from './components/ProtectedRoute'
import { routes } from './routes'
import BansPage from './pages/Bans'
import Dashboard from './pages/Dashboard'
import Layout from './styles/Layout'
import SettingsPage from './pages/Settings'
import Users from './pages/UserSearch'
import UserDetails from './pages/UserDetails'
import ForceLogout from './helpers/ForceLogout'

export default () => (
  <Layout>
    <Switch>
      <Route exact path={routes.login()} component={Login} />
      <ProtectedRoute path={routes.dashboard()} component={Dashboard} />
      <ProtectedRoute
        path={routes.userDetails(':username')}
        component={UserDetails}
      />
      <ProtectedRoute path={routes.users()} component={Users} />
      <ProtectedRoute path={routes.settings()} component={SettingsPage} />
      <ProtectedRoute path={routes.bans()} component={BansPage} />
      <Route component={ForceLogout} />
    </Switch>
  </Layout>
)

import * as React from 'react'
import * as get from 'lodash/get'
import NodeList from '../components/NodeList'
import { AnyFunction } from '../types/AnyFunction'
import { PageInfoFieldsFragment } from '../queries/PageInfoFields'
import gql from 'graphql-tag'
import { query } from '../apollo/Query'
import { compose } from 'react-apollo'
import User from '../components/User'

type Props = {
  queryResult?: any
  fetchMore?: AnyFunction
  visitedItems?: string[]
  refetch?: AnyFunction
}

const searchQueryOptions = ((site) => {
  switch (site) {
    case 'demi':
      return {
        queryName: 'searchDemiProfiles',
        fieldsFragmentName: 'PartialProfileFragment',
        fields: gql`
          fragment PartialProfileFragment on DemiProfile {
            username
            identifier
            userIdentifier
            profileImage
            isBanned
          }
        `
      }
    case 'lily':
      return {
        queryName: 'adminSearchLilyProfiles',
        fieldsFragmentName: 'PartialProfileFragment',
        fields: gql`
          fragment PartialProfileFragment on LilyProfile {
            username
            identifier
            userIdentifier
            profileImage
          }
        `
      }
    default:
      throw new Error(`Unknown site ${site}`)
  }
})(process.env.REACT_APP_SITE)

const searchProfiles = gql`
  query searchProfiles($cursor: String = null, $query: String!, $perPage: Int = 10) {
    searchProfiles: ${searchQueryOptions.queryName}(
      query: $query
      first: $perPage
      after: $cursor
    ) {
      pageInfo {
        ...pageInfoFields
      }
      edges {
        node {
          ...${searchQueryOptions.fieldsFragmentName}
        }
        cursor
      }
    }
  }
  ${PageInfoFieldsFragment}
  ${searchQueryOptions.fields}
`

const enhance = compose(query(searchProfiles))

export default enhance(
  ({ queryResult: { searchProfiles: data }, fetchMore, refetch }: Props) => {
    const edges = get(data, 'edges', [])

    return edges.length > 0 ? (
      <NodeList
        fetchMore={fetchMore}
        data={edges}
        renderItem={(props, idx) => (
          <User
            key={`user_${idx}_${props.identifier}`}
            {...props}
            refetchUsers={refetch}
          />
        )}
      />
    ) : (
      <p>Ei tuloksia.</p>
    )
  }
)

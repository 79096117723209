import gql from 'graphql-tag'

export const DemiProfileFieldsFragment = gql`
  fragment DemiProfileFragment on DemiProfile {
    id
    identifier
    userIdentifier
    username
    isBanned
    profileImage
    bio
    loginCount
    viewCount
    messageCount
  }
`

export const LilyProfileFieldsFragment = gql`
  fragment LilyProfileFragment on LilyProfile {
    id
    identifier
    userIdentifier
    username
    profileImage
    bio
  }
`

import * as React from 'react'

import adminUpdateSettingsMutation from '../queries/adminUpdateSettingsMutation'
import { Mutation } from '../apollo/Mutation'

const withAdminUpdateSettingsMutation = (WrappedComponent) => ({ ...rest }) => (
  <Mutation
    mutation={adminUpdateSettingsMutation}
    component={({
      mutationResult: { adminUpdateSettings },
      mutationError,
      mutationLoading,
      mutator
    }) => (
      <WrappedComponent
        adminUpdateSettings={(params) =>
          mutator({ variables: { settings: params } })
        }
        adminUpdateSettingsResult={adminUpdateSettings}
        adminUpdateSettingsError={mutationError}
        adminUpdateSettingsLoading={mutationLoading}
        {...rest}
      />
    )}
  />
)

export default withAdminUpdateSettingsMutation

import FontAwesome from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import * as React from 'react'

import IconSpinner from './IconSpinner'

type Props = {
  children?: React.ReactNode
}

function LoadingSpinner({ children }: Props) {
  return (
    <IconSpinner>
      <FontAwesome icon={faSpinner} />
      {children && <> {children}</>}
    </IconSpinner>
  )
}

export default LoadingSpinner

/**
 * This file contains the custom <Query> component and the @query decorator.
 * The <Query> component unifies loading and error behavior across the application.
 *
 * Use <Query> similarly to react-routers <Route>. Pass a GraphQL query, a Component to
 * render with the data and optionally some variables. The Component will be rendered
 * with the query data in the `queryResult` prop, plus all other props that was passed
 * to the <Query> component.
 *
 * The decorator works much the same way, except as a higher-order component. It takes
 * a query and an optional function that should return variables for the query to use.
 * You can also add a `variables` prop to the decorated component when mounting it.
 */

import * as React from 'react'
import { Query as ApolloQuery } from 'react-apollo'
import Loading from '../components/Loading'
import { createPaginator } from './paginate'
import * as has from 'lodash/has'
import * as get from 'lodash/get'
import { observer } from 'mobx-react'
import { handleAuthError } from './handleAuthError'

export const Query = observer(
  ({
    showWhileLoading = false,
    query: queryProp,
    variables,
    component: Component,
    paginate = true,
    skip = false,
    ...rest
  }: {
    query: any
    component: any
    variables?: object
    showWhileLoading?: boolean
    paginate?: boolean
    skip?: boolean
  }) => {
    return (
      <ApolloQuery variables={variables} query={queryProp} skip={skip}>
        {({ loading, error, data = {}, fetchMore, refetch, client }) => {
          handleAuthError(error)

          if (loading && !showWhileLoading) {
            return (
              <Loading loading={loading} error={error} label="Haetaan tietoja..." />
            )
          }

          const queryName = Object.keys(data)[0]

          const paginator =
            has(data, `${queryName}.edges`) && has(data, `${queryName}.pageInfo`)
              ? createPaginator(data[queryName], queryName, fetchMore)
              : () => {}

          return (
            <Component
              {...rest}
              apolloClient={client}
              refetch={refetch}
              queryError={error}
              queryLoading={loading}
              fetchMore={paginate ? paginator : false}
              queryResult={{ ...get(rest, 'queryResult', {}), ...data }}
            />
          )
        }}
      </ApolloQuery>
    )
  }
)

export const query = (
  staticQuery,
  getVariables: Function = () => null
): Function => (Component) => ({
  query: queryProp = staticQuery,
  ...rest
}: {
  query: any
  variables: any
}) => {
  const { variables = getVariables(rest) } = rest

  return (
    <Query query={queryProp} component={Component} {...rest} variables={variables} />
  )
}

import * as React from 'react'
import FontAwesome from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/fontawesome-free-solid'
import styled from 'styled-components'

const ReportsDisplay = styled.span`
  font-weight: bold;
  font-size: 0.667rem;
  white-space: nowrap;
  color: #666;

  svg {
    color: #999;
  }
`

export default ({ count }) => (
  <ReportsDisplay>
    <FontAwesome icon={faExclamationCircle} /> {count}
  </ReportsDisplay>
)

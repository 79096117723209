import gql from 'graphql-tag'

export const ReplyFieldsFragment = gql`
  fragment ReplyFields on Reply {
    id
    type
    createdAt
    updatedAt
    author
    body {
      html
      plain
      raw
    }
    thread {
      id
      slug
    }
  }
`

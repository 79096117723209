import * as React from 'react'
import styled from 'styled-components'

import ErrorDetails from '../components/ErrorDetails'
import RadioButtonInput from '../forms/RadioButtonInput'
import withSettings from '../components/withSettings'
import withAdminUpdateSettings from '../components/withAdminUpdateSettings'

const Section = styled.div`
  margin-bottom: 30px;
`

const FormRow = styled.div`
  margin-bottom: 10px;
`

type Props = {
  settings?: {
    signUpsEnabled
  }
  settingsError?: Error
  settingsLoading: boolean
  refetchSettings: Function
  adminUpdateSettings: Function
  adminUpdateSettingsError?: Error
  adminUpdateSettingsLoading: boolean
}

class SettingsPage extends React.Component<Props, {}> {
  render() {
    const {
      settings,
      settingsError,
      settingsLoading,
      refetchSettings,
      adminUpdateSettings,
      adminUpdateSettingsError,
      adminUpdateSettingsLoading
    } = this.props

    if (settingsError) {
      return renderPage(<ErrorDetails error={settingsError} />)
    }

    if (adminUpdateSettingsError) {
      return renderPage(<ErrorDetails error={adminUpdateSettingsError} />)
    }

    const signupsEnabled = settings ? settings.signUpsEnabled : undefined

    return renderPage(
      <div>
        <Section>
          <h2>Rekisteröityminen</h2>
          <FormRow>
            <RadioButtonInput
              onChange={() => {
                adminUpdateSettings({ signUpsEnabled: false }).then(refetchSettings)
              }}
              label="Rekisteröityminen tilapäisesti pois käytöstä"
              name="registrationDisabled"
              value="registrationDisabled"
              checked={!signupsEnabled}
              disabled={settingsLoading || adminUpdateSettingsLoading}
              loading={
                typeof signupsEnabled === 'undefined' ||
                (signupsEnabled && adminUpdateSettingsLoading)
              }
            />
          </FormRow>
          <FormRow>
            <RadioButtonInput
              onChange={() => {
                adminUpdateSettings({ signUpsEnabled: true }).then(refetchSettings)
              }}
              label="Rekisteröityminen käytössä"
              name="registrationEnabled"
              value="registrationEnabled"
              checked={!!signupsEnabled}
              disabled={settingsLoading || adminUpdateSettingsLoading}
              loading={
                typeof signupsEnabled === 'undefined' ||
                (!signupsEnabled && adminUpdateSettingsLoading)
              }
            />
          </FormRow>
        </Section>
      </div>
    )

    function renderPage(content) {
      return (
        <div>
          <h1>Asetukset</h1>
          <div>{content}</div>
        </div>
      )
    }
  }
}

export default withSettings(withAdminUpdateSettings(SettingsPage))

import gql from 'graphql-tag'

import {
  DemiProfileFieldsFragment,
  LilyProfileFieldsFragment
} from './ProfileFields'

const queryOptions = ((site) => {
  switch (site) {
    case 'demi':
      return {
        profileFieldName: 'demi',
        fieldsFragmentName: 'DemiProfileFragment',
        fields: DemiProfileFieldsFragment
      }
    case 'lily':
      return {
        profileFieldName: 'lilyProfile',
        fieldsFragmentName: 'LilyProfileFragment',
        fields: LilyProfileFieldsFragment
      }
    default:
      throw new Error(`Unknown site ${site}`)
  }
})(process.env.REACT_APP_SITE)

export default gql`
  query GetViewer {
    viewer {
      id
      ${queryOptions.profileFieldName} {
        ...${queryOptions.fieldsFragmentName}
      }
    }
  }
  ${queryOptions.fields}
`

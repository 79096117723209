import * as React from 'react'
import DropdownButton from './DropdownButton'
import FontAwesome from '@fortawesome/react-fontawesome'
import { faBan, faThumbsUp } from '@fortawesome/fontawesome-free-solid'
import { AnyFunction } from '../types/AnyFunction'

export default ({
  isBanned,
  onClick,
  onChange,
  value
}: {
  isBanned: boolean
  onClick?: AnyFunction
  onChange: AnyFunction
  value: string
}) => (
  <DropdownButton
    value={value}
    onChange={onChange}
    onClick={isBanned ? onClick : undefined}
    label={
      <>
        {isBanned ? <FontAwesome icon={faThumbsUp} /> : <FontAwesome icon={faBan} />}
        {isBanned ? 'Palauta käyttäjä' : 'Bannaa käyttäjä'}
      </>
    }>
    {!isBanned
      ? [
          {
            value: 'ONE_DAY',
            label: 'Yksi päivä'
          },
          {
            value: 'ONE_WEEK',
            label: 'Yksi viikko'
          },
          {
            value: 'TWO_WEEKS',
            label: 'Kaksi viikkoa'
          },
          {
            value: 'ONE_MONTH',
            label: 'Yksi kuukausi'
          },
          {
            value: 'TWO_MONTHS',
            label: 'Kaksi kuukautta'
          },
          {
            value: 'ONE_YEAR',
            label: 'Yksi vuosi'
          }
        ]
      : []}
  </DropdownButton>
)

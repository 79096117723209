import * as React from 'react'
import styled from 'styled-components'
import { routes } from '../routes'
import UserIndicator from './UserIndicator'
import demiLogoInverted from '../img/demi_logo_inverted.svg'
import lilyLogoInverted from '../img/lily_logo_inverted.svg'
import Button from './Button'
import { inject, observer } from 'mobx-react'

const { REACT_APP_MAIN_SITE_URL } = process.env

const HeaderBar = styled.header`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transform: translateY(0);
  opacity: 1;
  transition: background-color 333ms ease-out, transform 333ms ease-out,
    opacity 333ms ease-out;
  background-color: var(--black);
  color: var(--white);

  .error,
  .loading {
    display: none;
  }
`

const Logo = styled.a`
  height: 100%;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    height: 1.111rem;
  }

  @media (min-width: 620px) {
    padding: 0 1rem 0;
  }
`

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  @media (min-width: 620px) {
  }
`

const NavButton = styled(Button)`
  padding: 0.5rem 0.5rem 0.2rem;
  letter-spacing: 0;
  letter-spacing: 0.2px;
  font-family: var(--demi);
  font-size: 1.111rem;
  border: 0;

  @media (min-width: 440px) {
    margin-right: 0.5rem;
  }
`

const AngledLine = styled.div`
  width: 1.5px;
  height: 35px;
  background-color: var(--white);
  transform: rotate(25deg);
  margin: 0 0.5rem;
  display: none;

  @media (min-width: 440px) {
    display: block;
    margin: 0 1rem 0 0.5rem;
  }
`

const logoImage = ((site) => {
  switch (site) {
    case 'demi':
      return demiLogoInverted
    case 'lily':
      return lilyLogoInverted
    default:
      return null
  }
})(process.env.REACT_APP_SITE)

@inject('state')
@observer
class Header extends React.Component<any, any> {
  state = {
    searchOpen: false
  }

  render() {
    const { state } = this.props

    const logo = (
      <Logo href={REACT_APP_MAIN_SITE_URL}>
        <img src={logoImage} />
      </Logo>
    )

    return (
      <HeaderBar>
        {logo}
        {state.isLoggedIn && (
          <>
            <AngledLine />
            <Nav>
              <NavButton inverted to={routes.dashboard()}>
                Ilmiannot
              </NavButton>
              <NavButton inverted to={routes.users()}>
                Käyttäjähaku
              </NavButton>
              {process.env.REACT_APP_SITE === 'demi' && (
                <NavButton inverted to={routes.settings()}>
                  Asetukset
                </NavButton>
              )}
              {process.env.REACT_APP_SITE === 'demi' && (
                <NavButton inverted to={routes.bans()}>
                  Bannaukset
                </NavButton>
              )}
            </Nav>
            <UserIndicator showWhileLoading={false} />
          </>
        )}
      </HeaderBar>
    )
  }
}

export default Header

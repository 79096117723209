import { ApolloClient } from 'apollo-client'
import * as React from 'react'
import { withApollo, compose } from 'react-apollo'
import styled from 'styled-components'

import { AnyFunction } from '../types/AnyFunction'
import Button from './Button'
import Input from '../forms/Input'
import ListHeader from './ListHeader'
import Modal from './Modal'
import moderationReasons from '../helpers/moderationReasons'
import Select from '../forms/Select'
import { deleteThread } from '../queries/thread'
import { deleteReply } from '../queries/reply'

type Props = {
  client?: ApolloClient<any>
  type: 'thread' | 'reply'
  id?: string
  title?: string
  onSuccess: AnyFunction
  onCancel: AnyFunction
}

type State = {
  selectedReason: string
  customReason: string
}

const TEXTS = {
  thread: {
    heading: 'Haluatko varmasti poistaa keskustelun?',
    ingress:
      'Olet poistamassa keskustelua. Tätä toimintoa ei voida myöhemmin peruuttaa.',
    reasonPrompt:
      'Valitse alta syy keskustelun poistamiselle. Keskustelun aloittaja näkee syyn ilmoituksissaan.'
  },
  reply: {
    heading: 'Haluatko varmasti poistaa viestin?',
    ingress:
      'Olet poistamassa viestiä. Tätä toimintoa ei voida myöhemmin peruuttaa.',
    reasonPrompt:
      'Valitse alta syy viestin poistamiselle. Viestin kirjoittaja näkee syyn ilmoituksissaan.'
  }
}

const MUTATIONS = {
  thread: deleteThread,
  reply: deleteThread
}

const enhance = compose(withApollo)

@enhance
class DeleteModeratedEntityModal extends React.Component<Props, State> {
  state = {
    selectedReason: '',
    customReason: ''
  }

  delete = () => {
    const { client, type, id, onSuccess } = this.props
    const { selectedReason, customReason } = this.state

    const mutation = MUTATIONS[type]

    client
      .mutate({
        mutation,
        variables: {
          id,
          reason: selectedReason || customReason || undefined
        }
      })
      .then(onSuccess)
  }

  componentDidUpdate(prevProps) {
    // Reset input when id changes
    if (this.props.id !== prevProps.id) {
      this.setState({
        selectedReason: '',
        customReason: ''
      })
    }
  }

  render() {
    const { type, id, title, onCancel } = this.props
    const { selectedReason, customReason } = this.state
    const texts = TEXTS[type]
    return (
      <Modal visible={!!id} onClose={onCancel}>
        <ListHeader>{texts.heading}</ListHeader>
        <Section>{texts.ingress}</Section>
        {title && (
          <Section>
            <b>{title}</b>
          </Section>
        )}
        <Section>{texts.reasonPrompt}</Section>
        <Section>
          <Select
            value={selectedReason}
            onChange={({ target: { value } }) => {
              this.setState({ selectedReason: value })
            }}
            style={{ width: '100%' }}>
            <option value="">Muu</option>
            {moderationReasons[type].map((reason) => (
              <option key={reason} value={reason}>
                {reason}
              </option>
            ))}
          </Select>
          {selectedReason === '' && (
            <Input
              name="customReason"
              type="text"
              placeholder="Syötä syy"
              maxLength={255}
              value={customReason}
              onChange={({ target: { value } }) => {
                this.setState({ customReason: value })
              }}
              style={{ width: '100%', marginTop: '10px' }}
            />
          )}
        </Section>
        <ConfirmButton
          onClick={this.delete}
          disabled={!selectedReason && !customReason}>
          Vahvista
        </ConfirmButton>
        <CancelButton onClick={onCancel}>Peruuta</CancelButton>
      </Modal>
    )
  }
}

export default DeleteModeratedEntityModal

const Section = styled.div`
  margin: 2rem auto;
`

const ConfirmButton = styled(Button)`
  background: var(--red);
  border-color: var(--red);
  margin-bottom: 1rem;
  color: white;

  &:hover {
    border-color: var(--black);
  }

  &[disabled] {
    background-color: gray;
    border-color: gray;
  }
`

const CancelButton = styled(Button)`
  color: var(--red);
  border-color: transparent;
`

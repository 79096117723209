export default (label, icon, action, component?) => {
  const onClick = typeof action === 'function' ? action : undefined
  const href = typeof action === 'string' ? action : undefined
  const target = typeof href !== 'undefined' ? '_blank' : undefined

  return {
    label,
    icon,
    component,
    action: {
      href,
      target,
      onClick
    }
  }
}

import * as React from 'react'
import * as difference from 'lodash/difference'
import * as get from 'lodash/get'
import * as uniq from 'lodash/uniq'
import { AnyFunction } from '../types/AnyFunction'

type State = {
  newItems: string[]
  visitedItems: string[]
}

let unloadEventAdded = false

export default (localStorageKey: string, getIds: AnyFunction) => (
  Component
): any => {
  return class VisitedItemsManager extends React.Component<any, State> {
    static getDerivedStateFromProps = (nextProps, prevState) => {
      const idsInView = getIds(nextProps)
      const newItems = difference(idsInView, get(prevState, 'newItems', []))
      const unvisitedItems = difference(newItems, get(prevState, 'visitedItems', []))

      if (unvisitedItems.length > 0) {
        return {
          newItems: [...prevState.newItems, ...unvisitedItems]
        }
      }

      return null
    }

    state: State = {
      newItems: [],
      visitedItems: []
    }

    getVisitedItems = () => get(this, 'state.visitedItems', [])
    setVisitedItems = (value) => {
      this.setState({ visitedItems: value })
    }

    componentDidMount() {
      this.initializeSeenItems()

      if (!unloadEventAdded) {
        unloadEventAdded = true
        window.addEventListener('beforeunload', () => this.componentWillUnmount())
      }
    }

    componentWillUnmount() {
      this.persistSeenItems()
    }

    persistSeenItems = () => {
      const seenJson = JSON.stringify(
        uniq([...this.getVisitedItems(), ...this.state.newItems])
      )

      localStorage.setItem(localStorageKey, seenJson)
    }

    initializeSeenItems = () => {
      let savedVisited = JSON.parse(localStorage.getItem(localStorageKey))

      if (!savedVisited) {
        savedVisited = []
      }

      this.setVisitedItems(uniq(savedVisited))
    }

    markAsVisited = (id) => {
      const visited = uniq([...this.getVisitedItems(), id])
      this.setVisitedItems(visited)
    }

    render() {
      return (
        <Component
          markAsVisited={this.markAsVisited}
          visitedItems={this.state.visitedItems}
          {...this.props}
        />
      )
    }
  }
}

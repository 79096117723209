import * as React from 'react'

import { Query } from '../apollo/Query'
import profileQuery from '../queries/profileQuery'

export type Props = {
  profile?: {
    id: string
    username: string
  }
  profileError?: Error
  profileLoading: boolean
  refetchProfile: Function
}

const withUser = (WrappedComponent) => ({ username, ...rest }) => (
  <Query
    query={profileQuery}
    variables={{
      username
    }}
    skip={!username}
    showWhileLoading={true}
    component={({ queryResult: { profile }, queryError, queryLoading, refetch }) => {
      const props: Props = {
        profile,
        profileError: queryError,
        profileLoading: queryLoading && !!username,
        refetchProfile: refetch
      }
      return <WrappedComponent {...props} {...rest} />
    }}
  />
)

export default withUser

import * as React from 'react'
import styled from 'styled-components'
import styledTs from 'styled-components-ts'
import is from 'styled-is'
import { faBan, faEye, faTrash } from '@fortawesome/fontawesome-free-solid'
import * as get from 'lodash/get'
import * as truncate from 'lodash/truncate'
import Button from './Button'
import ReportTarget from './ReportTarget'
import { AnyFunction } from '../types/AnyFunction'
import FontAwesome from '@fortawesome/react-fontawesome'
import { compose, withApollo } from 'react-apollo'
import BanButton from './BanButton'
import UserIpBanButton from './UserIpBanButton'
import UsernameDeleteProfileButton from './UsernameDeleteProfileButton'
import UserInfo from './UserInfo'
import ApolloClient from 'apollo-client/ApolloClient'
import { banUser, unbanUser } from '../queries/banUser'
import { getProfileUrl } from '../helpers/profiles'

const Title = styledTs<{ bold: boolean }>(styled.h3)`
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  line-height: 1.5;
  color: var(--black);

  ${is('bold')`
    font-weight: bold;
  `}
`

const BannedIcon = styled(FontAwesome)`
  color: var(--salmon);
  margin-left: 1rem;
`

type Props = {
  refetchReports: AnyFunction
  markAsVisited: AnyFunction
  isUnseen: boolean
  set?: AnyFunction
  report: any
  client?: ApolloClient<any>
  queryResult?: any
}

type State = {
  isBanned: boolean
  banDuration: string
}

const enhance = compose(withApollo)

@enhance
class ReportedUser extends React.Component<Props, State> {
  state: State = {
    isBanned: get(this.props.report, 'target.isBanned', false),
    banDuration: ''
  }

  ban = (banDuration) => {
    const { report, client } = this.props

    client
      .mutate({
        mutation: banUser,
        variables: { identifier: report.target.id, banDuration }
      })
      .then(() => {
        this.setState({
          isBanned: true,
          banDuration: ''
        })
      })
  }

  unban = () => {
    const { report, client } = this.props

    client
      .mutate({
        mutation: unbanUser,
        variables: { identifier: report.target.id }
      })
      .then(() => {
        this.setState({
          isBanned: false,
          banDuration: ''
        })
      })
  }

  onSelectBanDuration = (value) => {
    this.setState({
      banDuration: value
    })

    this.ban(value)
  }

  createModerationActions = (confirmModal) => [
    ({ target }) =>
      target && target.username ? (
        <Button
          href={getProfileUrl(target.username)}
          target="_blank"
          key={`open_profile_button_${target.id}`}>
          <FontAwesome icon={faEye} />
          Avaa profiili
        </Button>
      ) : null,
    ({ target }) =>
      target ? (
        <BanButton
          onClick={this.unban}
          key={`user_ban_button_${target.id}`}
          value={this.state.banDuration}
          onChange={this.onSelectBanDuration}
          isBanned={this.isBanned()}
        />
      ) : null,
    ({ target }) =>
      target ? (
        <UsernameDeleteProfileButton
          confirmWithModal={confirmModal}
          key={`delete_profile_button_${target.id}`}
          username={target.username}
        />
      ) : null,
    ({ target }) =>
      target ? (
        <UserIpBanButton
          confirmWithModal={confirmModal}
          key={`ip_ban_button_${target.id}`}
          userId={target.userIdentifier}
        />
      ) : null
  ]

  renderReportContent = (report) => {
    if (!report.target || !report.target.userIdentifier) {
      return null
    }
    return <UserInfo userId={report.target.userIdentifier} />
  }

  isBanned = () => {
    return this.state.isBanned
  }

  render() {
    const { report, isUnseen, ...props } = this.props
    const username = get(report, 'target.username', 'Poistunut demittäjä')
    const isBanned = this.isBanned()

    return (
      <ReportTarget
        {...props}
        username={username}
        report={report}
        createModerationActions={this.createModerationActions}
        renderReportContent={this.renderReportContent}>
        <Title bold={isUnseen}>
          {truncate(report.reason, { length: 105 })}
          {isBanned && <BannedIcon icon={faBan} />}
        </Title>
      </ReportTarget>
    )
  }
}

export default ReportedUser

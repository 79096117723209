import * as React from 'react'
import styled from 'styled-components'
import styledTs from 'styled-components-ts'
import Button from './Button'
import { AnyFunction } from '../types/AnyFunction'
import { Collapse } from 'react-collapse'
import { presets } from 'react-motion'

const MenuWrapper = styled.nav`
  border: 3px solid #444444;
  border-radius: 2px;
  position: relative;
  width: 100%;

  &:hover {
    border-color: var(--black);
  }

  + * {
    margin-top: 1rem;
  }
`

const MainButton = styled(Button)`
  display: block;
  width: 100%;
  outline: none;
  cursor: pointer;
  border: 0;
  border-radius: 0;

  svg {
    margin-right: 0.25rem;

    &:last-of-type {
      margin-right: 1rem;
    }
  }
`

const OptionButton = styledTs<{ selected: boolean }>(styled(MainButton))`
  padding-bottom: 1rem;
`

const OptionsWrapper = styled(Collapse)`
  background: var(--white);
  border: 3px solid #444;
  border-top: 0;
  border-radius: 2px;
  position: absolute;
  left: -3px;
  z-index: 10;
  width: calc(100% + 6px);

  &:hover {
    border-color: var(--black);
  }

  button:last-child {
    border-bottom: 0;
    padding-bottom: calc(1rem + 4px);
  }
`

type Props = {
  children?: Array<{ label: React.ReactNode; value: string }>
  label: React.ReactNode
  onClick?: AnyFunction
  onChange: AnyFunction
  value: string
  disabled?: boolean
}

type State = {
  isOpen: boolean
}

class DropdownButton extends React.Component<Props, State> {
  state = {
    isOpen: false
  }

  toggleOpen = (e) => {
    e.preventDefault()

    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  optionClick = (value) => () => {
    this.setState({
      isOpen: false
    })

    this.props.onChange(value)
  }

  render() {
    const {
      children,
      label,
      onClick: onSingleButtonClick,
      value: currentValue,
      disabled = false
    } = this.props

    const { isOpen } = this.state

    return children.length > 0 ? (
      <MenuWrapper>
        <MainButton disabled={disabled} onClick={this.toggleOpen}>
          {label}
        </MainButton>
        {!disabled && (
          <OptionsWrapper springConfig={presets.stiff} isOpened={isOpen}>
            {children.map(({ label: optionLabel, value }, idx) => (
              <OptionButton
                key={`option_${value}_${idx}`}
                selected={value === currentValue}
                onClick={this.optionClick(value)}>
                {optionLabel}
              </OptionButton>
            ))}
          </OptionsWrapper>
        )}
      </MenuWrapper>
    ) : (
      <Button disabled={disabled} onClick={onSingleButtonClick}>
        {label}
      </Button>
    )
  }
}

export default DropdownButton

import gql from 'graphql-tag'

export const lockThread = gql`
  mutation lockThread($threadId: ID!) {
    lockThread(threadId: $threadId)
  }
`

export const unLockThread = gql`
  mutation unLockThread($threadId: ID!) {
    unLockThread(threadId: $threadId)
  }
`

export const deleteThread = gql`
  mutation deleteThread($id: ID!, $reason: String) {
    deleteThread(id: $id, reason: $reason)
  }
`

import * as React from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import ToggleButton from './ToggleButton'
import Flex from 'styled-flex-component'
import Report from './Report'
import { Collapse } from 'react-collapse'
import { presets } from 'react-motion'
import ReportsDisplay from './ReportsDisplay'
import { AnyFunction } from '../types/AnyFunction'

const ReportTargetItem = styled.div`
  padding: 0.833rem 1.111rem;
  position: relative;
`

const Header = styled.header`
  margin-bottom: 0.6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
`

const Username = styled.span`
  font-size: 0.778rem;
  font-weight: bold;
  color: var(--salmon);
  margin-right: 0.6rem;
`

const Timestamp = styled.span`
  color: var(--grey);
  font-weight: 100;
  font-size: 0.667rem;
  white-space: nowrap;
  margin-right: 1rem;
`

const Content = styled(Flex)``

type displayStateType = 'open' | 'closed'

type State = {
  displayState: displayStateType
}

type Props = {
  children: AnyFunction | React.ReactNode
  markAsVisited: AnyFunction
  report: any
  refetchReports: AnyFunction
  createModerationActions: AnyFunction
  renderReportContent?: AnyFunction
  username?: React.ReactNode
}

class ReportTarget extends React.Component<Props, State> {
  state: State = {
    displayState: 'closed'
  }

  toggleDisplayState = (
    setTo: displayStateType = this.state.displayState === 'open' ? 'closed' : 'open'
  ) => {
    const { markAsVisited } = this.props

    if (setTo === 'open') {
      markAsVisited()
    }

    this.setState({
      displayState: setTo
    })
  }

  render() {
    const {
      username,
      report,
      refetchReports,
      children,
      createModerationActions,
      renderReportContent
    } = this.props

    const target = report.target || {}
    const {
      author = username || 'Poistunut demittäjä',
      updatedAt = report.createdAt
    } = target

    const { displayState } = this.state

    let timestamp = DateTime.fromISO(updatedAt)
    timestamp = timestamp.isValid ? timestamp : DateTime.fromSQL(updatedAt)

    return (
      <ReportTargetItem>
        <Header>
          <Username>{author}</Username>
          <Timestamp>
            {timestamp.setLocale('fi').toLocaleString(DateTime.DATETIME_SHORT)}
          </Timestamp>
          {report.numDuplicates > 0 && (
            <ReportsDisplay count={report.numDuplicates + 1} />
          )}
        </Header>
        <Content alignCenter>
          {typeof children === 'function' ? children(displayState) : children}
          <ToggleButton
            right
            state={displayState}
            onClick={() => this.toggleDisplayState()}
          />
        </Content>
        <Collapse isOpened={displayState === 'open'} springConfig={presets.stiff}>
          <Report
            {...report}
            refetchReports={refetchReports}
            getActions={createModerationActions}
            renderReportContent={renderReportContent}
          />
        </Collapse>
      </ReportTargetItem>
    )
  }
}

export default ReportTarget

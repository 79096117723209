/**
 * This file contains the custom <Mutation> component and the @mutate
 * decorator that makes working with Apollo mutations a bit smoother.
 *
 * <Mutation> works very similarly to the custom <Query> component, but
 * delegates loading and error handling to the wrapped component. Crucially,
 * it forwards the `onCompleted` prop to Apollo's <Mutation> so that the
 * parent can define what happens after the mutation is completed as is
 * often the best way to handle it. As a shortcut, the parent can also
 * set a `completedRoute` prop on the <Mutation> and it will redirect the
 * UI to the provided route when completed.
 *
 * In the wrapped component, use the function passed through the `mutator`
 * prop to perform the mutation. Use `mutationLoading` to display a
 * "loading" state in the component.
 *
 * The decorator applies a mutation in the same way but as a higher-order
 * component.
 */

import * as React from 'react'
import { compose, Mutation as ApolloMutation } from 'react-apollo'
import * as get from 'lodash/get'
import { RouteComponentProps, withRouter } from 'react-router'
import { AnyFunction } from '../types/AnyFunction'
import { handleAuthError } from './handleAuthError'
import { observer } from 'mobx-react'

interface Props extends RouteComponentProps<any> {
  history: any
  completedRoute?: string
  onCompleted?: any
  mutation: any
  update?: AnyFunction
  component: any
  variables?: object
  refetchQueries?: any[]
}

const enhance = compose(withRouter, observer)

// noinspection TsLint
export const Mutation = enhance(
  ({
    mutation,
    update,
    component: Component,
    history = null,
    completedRoute = '',
    variables,
    refetchQueries,
    onCompleted = () => {
      if (history && completedRoute) {
        history.push(completedRoute)
      }
    },
    ...rest
  }: Props) => {
    return (
      <ApolloMutation
        refetchQueries={refetchQueries}
        onCompleted={onCompleted}
        mutation={mutation}
        update={update}
        variables={variables}>
        {(mutatorFunction, { loading, error, data = {} }) => {
          handleAuthError(error)

          const queryName = Object.keys(data)[0]
          const mutationResult = get(data, queryName, data)

          return (
            <Component
              onCompleted={onCompleted}
              mutationLoading={loading}
              mutationError={error}
              mutationResult={mutationResult}
              mutator={mutatorFunction}
              {...rest}
            />
          )
        }}
      </ApolloMutation>
    )
  }
)

export const mutate = (staticMutation, staticUpdate?): Function => (Component) => ({
  mutation = staticMutation,
  update = staticUpdate,
  ...rest
}: {
  update?: AnyFunction
  mutation: any
}) => (
  <Mutation mutation={mutation} update={update} component={Component} {...rest} />
)

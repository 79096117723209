import * as React from 'react'
import Input, { InputProps } from './Input'
import styled from 'styled-components'
import styledTs from 'styled-components-ts'
import is from 'styled-is'

export interface FieldProps extends InputProps {
  label?: React.ReactNode
  className?: string
  children?: any
  style?: any
}

type StyledFieldProps = {
  disabled?: boolean
}

const FieldWrapper = styledTs<StyledFieldProps>(styled.div)`
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  input {
    width: 100%;
  }
  
  ${is('disabled')`
    
    input {
      border: 0;
      padding: 0;
      background: transparent;
    }
  `}
`

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`

const FieldLabel = styled.label`
  font-weight: bold;
`

const FieldLabelDiv = FieldLabel.withComponent('div')

const Field = (props: FieldProps) => {
  const { label, className = '', children, id, disabled, style, ...rest } = props

  const InputComponent = children ? (
    React.Children.map(children, (child: any) =>
      React.cloneElement(child, {
        id,
        disabled,
        ...rest
      })
    )
  ) : (
    <Input id={id} disabled={disabled} {...rest} />
  )

  // Create a label if the Field was provided one. Use a label element
  // only if the Field was provided with an id prop. This can be used
  // to avoid nested labels which can lead to buggy UI.
  const LeftLabel = label ? (
    id ? (
      <FieldLabel htmlFor={id}>{label}</FieldLabel>
    ) : (
      <FieldLabelDiv>{label}</FieldLabelDiv>
    )
  ) : null

  return (
    <FieldWrapper className={className} style={style}>
      {LeftLabel ? <Labels>{LeftLabel}</Labels> : null}
      {InputComponent}
    </FieldWrapper>
  )
}

export default Field

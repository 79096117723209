import { faTrashAlt } from '@fortawesome/fontawesome-free-solid'
import FontAwesome from '@fortawesome/react-fontawesome'
import * as React from 'react'
import styled from 'styled-components'

import { ConfirmModal } from '../helpers/ConfirmModal'
import ErrorDetails from '../components/ErrorDetails'
import Input from '../forms/Input'
import IpBanButton from '../components/IpBanButton'
import LoadingSpinner from '../components/LoadingSpinner'
import withAdminBannedIpAddresses from '../components/withAdminBannedIpAddresses'
import withAdminUnbanIpAddress from '../components/withAdminUnbanIpAddress'

const Section = styled.div`
  margin-bottom: 30px;
`

const FormSection = styled.div`
  margin-bottom: 10px;
`

const IpAddressListItem = styled.li`
  margin-bottom: 10px;

  a {
    margin-left: 15px;
  }
`

const IpAddress = styled.span`
  display: inline-block;
  min-width: 20ch;
  font-family: monospace;
`

const LinkButton = styled.a`
  cursor: pointer;

  &.disabled {
    color: var(--grey);
    pointer-events: none;
    cursor: default;
  }
`

type Props = {
  adminBannedIpAddresses?: string[]
  adminBannedIpAddressesError?: Error
  adminBannedIpAddressesLoading: boolean
  refetchAdminBannedIpAddresses: Function
  adminUnbanIpAddress: Function
  adminUnbanIpAddressError?: Error
  adminUnbanIpAddressLoading: boolean
}

type State = {
  ipAddressToBan: string
  confirmIpAddressToUnban: string
}

class BansPage extends React.Component<Props, State> {
  state = {
    ipAddressToBan: '',
    confirmIpAddressToUnban: undefined
  }

  confirmUnban = (ipAddress) => {
    const { adminUnbanIpAddress, refetchAdminBannedIpAddresses } = this.props
    adminUnbanIpAddress(ipAddress).then(refetchAdminBannedIpAddresses)
    this.setState({ confirmIpAddressToUnban: undefined })
  }

  render() {
    const {
      adminBannedIpAddresses,
      adminBannedIpAddressesError,
      adminBannedIpAddressesLoading,
      adminUnbanIpAddressError,
      adminUnbanIpAddressLoading
    } = this.props
    const { ipAddressToBan, confirmIpAddressToUnban } = this.state

    const anyError =
      adminBannedIpAddressesError || adminUnbanIpAddressError || undefined

    const anyLoading =
      adminBannedIpAddressesLoading || adminUnbanIpAddressLoading || false

    if (anyError) {
      return renderPage(<ErrorDetails error={anyError} />)
    }

    return renderPage(
      <div>
        <Section>
          <h2>Bannatut IP-osoitteet</h2>
          {!adminBannedIpAddresses ? (
            <>
              <LoadingSpinner /> Ladataan...
            </>
          ) : adminBannedIpAddresses.length > 0 ? (
            <ul>
              {adminBannedIpAddresses.map((ipAddress) => (
                <IpAddressListItem key={ipAddress}>
                  <IpAddress>{ipAddress}</IpAddress>{' '}
                  <LinkButton
                    className={anyLoading && 'disabled'}
                    onClick={() => {
                      this.setState({
                        confirmIpAddressToUnban: ipAddress
                      })
                    }}>
                    <FontAwesome icon={faTrashAlt} />
                  </LinkButton>
                </IpAddressListItem>
              ))}
            </ul>
          ) : (
            <p>Ei bannattuja IP-osoitteita.</p>
          )}
        </Section>
        <Section>
          <h2>Bannaa IP-osoite</h2>
          <FormSection>
            <Input
              name="ipAddressToBan"
              disabled={anyLoading}
              value={ipAddressToBan}
              onChange={({ target: { value } }) => {
                this.setState({ ipAddressToBan: value })
              }}
            />
          </FormSection>
          <IpBanButton ipAddress={ipAddressToBan} disabled={anyLoading} />
        </Section>
        <ConfirmModal
          subject="poistaa IP-bannauksen"
          preview={
            <>
              Olet poistamassa bannausta IP-osoitteelta{' '}
              <b>{confirmIpAddressToUnban}</b>.
            </>
          }
          final={true}
          visible={!!confirmIpAddressToUnban}
          onConfirm={() => this.confirmUnban(confirmIpAddressToUnban)}
          onClose={() => {
            this.setState({ confirmIpAddressToUnban: undefined })
          }}
        />
      </div>
    )

    function renderPage(content) {
      return (
        <div>
          <h1>IP-bannaukset</h1>
          <div>{content}</div>
        </div>
      )
    }
  }
}

export default withAdminBannedIpAddresses(withAdminUnbanIpAddress(BansPage))

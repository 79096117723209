import * as React from 'react'
import { optimism } from '../helpers/optimism'
import { AnyFunction } from '../types/AnyFunction'
import * as get from 'lodash/get'
import * as set from 'lodash/set'
import * as merge from 'lodash/merge'

export default (getKey: AnyFunction, propName: string) => (Component) => {
  return class Optimistic extends React.Component<any, any> {
    optimisticHelper = optimism(getKey(this.props))
    unloadEventAdded = false

    state = {
      [propName]: false
    }

    unloadEventListener = () => this.componentWillUnmount()

    onChange() {
      const currentValue = get(this.props, propName)
      const optimisticValue = this.optimisticHelper.get()

      const shouldBeValue =
        currentValue !== optimisticValue ? optimisticValue : currentValue

      if (this.state[propName] === shouldBeValue) {
        return
      }

      this.setState({
        [propName]: shouldBeValue
      })
    }

    componentDidMount() {
      if (!this.unloadEventAdded) {
        this.unloadEventAdded = true
        window.addEventListener('beforeunload', this.unloadEventListener)
      }

      this.onChange()
    }

    componentDidUpdate() {
      this.onChange()
    }

    componentWillUnmount() {
      // Save to sessionStorage
      this.optimisticHelper.persist()

      window.removeEventListener('beforeunload', this.unloadEventListener)
      this.unloadEventAdded = false
    }

    set = (value) => {
      // Refresh key if necessary
      const newestKey = getKey(this.props)

      if (!this.optimisticHelper.key || this.optimisticHelper.key !== newestKey) {
        this.optimisticHelper = optimism(newestKey)
      }

      this.optimisticHelper.set(value)
      this.forceUpdate()
    }

    render() {
      const props = merge({}, this.props, set({}, propName, this.state[propName]))

      return <Component {...props} set={this.set} />
    }
  }
}

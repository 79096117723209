import { mutate } from '../apollo/Mutation'
import * as React from 'react'
import styled from 'styled-components'

import adminUpdateLilyProfileMutation from '../queries/adminUpdateLilyProfileMutation'
import adminUpdateDemiProfileMutation from '../queries/adminUpdateDemiProfileMutation'
import Button from './Button'

const SITE = process.env.REACT_APP_SITE

type Props = {
  profile: any
  confirmWithModal: Function
  onCompleted: Function
  mutator?: Function
}
type State = {}

const ActionButton = styled(Button)`
  margin-top: 30px;
`

const ProfileImageImg = styled.img`
  display: block;
  width: 100%;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
`

const updateProfileMutation =
  (SITE === 'demi' && adminUpdateDemiProfileMutation) ||
  (SITE === 'lily' && adminUpdateLilyProfileMutation) ||
  null

@mutate(updateProfileMutation)
class ProfileImageAdministration extends React.Component<Props, State> {
  submit = () => {
    const { profile, confirmWithModal, onCompleted, mutator } = this.props

    confirmWithModal(
      'poistaa profiilikuvan?',
      <>
        Olet poistamassa profiilikuvaa käyttäjältä <b>{profile.username}</b>.
      </>,
      () => {
        mutator({
          variables: {
            id: profile.id,
            profile: {
              profileImage: null
            }
          }
        }).then(onCompleted)
      }
    )
  }

  render() {
    const { profile } = this.props

    return (
      <>
        {profile.profileImage ? (
          <>
            <ProfileImageImg src={profile.profileImage} />
            <ActionButton onClick={this.submit}>Poista profiilikuva</ActionButton>
          </>
        ) : (
          <div>
            <i>Käyttäjällä ei ole profiilikuvaa.</i>
          </div>
        )}
      </>
    )
  }
}

export default ProfileImageAdministration

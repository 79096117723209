import * as React from 'react'

import { Query } from '../apollo/Query'
import partnersQuery from '../queries/partnersQuery'
import { flattenConnection } from '../helpers/graphql'

export type Props = {
  partners?: Array<{
    id: string
    name: string
  }>
  partnersError?: Error
  partnersLoading: boolean
  refetchPartners: Function
}

const withPartnersQuery = (WrappedComponent) => ({ ...rest }) => (
  <Query
    query={partnersQuery}
    showWhileLoading={true}
    component={({
      queryResult: { partners: partnersResult },
      queryError,
      queryLoading,
      refetch
    }) => {
      const partners = !!partnersResult
        ? flattenConnection(partnersResult)
        : partnersResult
      if (partners && partners.sort) {
        partners.sort((a, b) => a.name.localeCompare(b.name))
      }
      const props: Props = {
        partners,
        partnersError: queryError,
        partnersLoading: queryLoading,
        refetchPartners: refetch
      }
      return <WrappedComponent {...props} {...rest} />
    }}
  />
)

export default withPartnersQuery

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import styled from 'styled-components'
import styledTs from 'styled-components-ts'
import FontAwesome from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/fontawesome-free-solid'

const enum ModalStates {
  hidden = 'hidden',
  visible = 'visible',
  closing = 'closing',
  opening = 'opening'
}

type ModalState = {
  modalState: ModalStates
}

const Overlay = styledTs<ModalState>(styled.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: white;
  transition: opacity 0.3s ease-out;
  z-index: 99;

  ${({ modalState }) =>
    modalState === 'closing' || modalState === 'opening'
      ? 'opacity: 0;'
      : 'opacity: 1;'}
`

const ContentWrapper = styled.div`
  background: white;
  width: 95%;
  max-width: 41.111rem;
  max-height: 100%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
`

const Content = styled.div`
  padding: 2rem 1rem 1rem;

  @media (min-width: 40rem) {
    padding: 2em;
  }
`

const CloseButton = styled.button`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 0;
  border: 0;
  appearance: none;
  font-size: 1.5rem;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    color: white;
    background: var(--red);
  }
`

type ModalProps = {
  visible: boolean
  children?: React.ReactNode
  onClose?: Function
}

class Modal extends React.Component<ModalProps, ModalState> {
  state = {
    modalState: ModalStates.hidden
  }

  portalMount = document.createElement('div')

  componentWillMount() {
    document.body.appendChild(this.portalMount)
  }

  componentDidMount() {
    const { visible } = this.props
    const { modalState } = this.state

    if (visible && modalState === ModalStates.hidden) {
      this.openModal()
    } else if (!visible && modalState !== ModalStates.hidden) {
      this.closeModal()
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this.portalMount)
  }

  componentWillReceiveProps({ visible: nextVisible }: { visible: boolean }) {
    const { visible } = this.props

    if (visible === nextVisible) {
      return
    }

    if (nextVisible) {
      this.openModal()
    } else {
      this.closeModal()
    }
  }

  onEscPress = (e) => {
    if (e.keyCode === 27 && this.state.modalState !== ModalStates.hidden) {
      this.onClose(e)
    }
  }

  onClose = (e) => {
    e.preventDefault()
    const { onClose } = this.props

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  closeModal() {
    window.removeEventListener('keyup', this.onEscPress)
    this.setState({ modalState: ModalStates.closing })

    setTimeout(() => this.setState({ modalState: ModalStates.hidden }), 300)
  }

  openModal() {
    this.setState({ modalState: ModalStates.opening })

    setTimeout(() => {
      this.setState({ modalState: ModalStates.visible })
      window.addEventListener('keyup', this.onEscPress)
    }, 1)
  }

  render() {
    const { modalState } = this.state

    return ReactDOM.createPortal(
      <React.Fragment>
        {modalState !== ModalStates.hidden ? (
          <Overlay modalState={modalState}>
            <CloseButton onClick={this.onClose}>
              <FontAwesome icon={faTimes} />
            </CloseButton>
            <ContentWrapper>
              <Content>{this.props.children}</Content>
            </ContentWrapper>
          </Overlay>
        ) : null}
      </React.Fragment>,
      this.portalMount
    )
  }
}

export default Modal

import gql from 'graphql-tag'

export default gql`
  query partners {
    partners {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

import * as React from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter } from 'react-router-dom'

import { ConfirmModalContext, ConfirmModal } from './helpers/ConfirmModal'
import Router from './Router'
import Header from './components/Header'

type Props = {
  client: any
}

type State = {
  confirmModalVisible: boolean
  confirmSubject: string
  confirmPreview: React.ReactNode
  confirmIsFinal: boolean
}

class Root extends React.Component<Props, State> {
  state: State = {
    confirmModalVisible: false,
    confirmSubject: '',
    confirmPreview: '',
    confirmIsFinal: true
  }

  onConfirmAction: Function = () => {}

  confirmWithModal = (subject, preview, onConfirm, isFinal = true) => {
    this.onConfirmAction = onConfirm

    this.setState({
      confirmModalVisible: true,
      confirmSubject: subject,
      confirmPreview: preview,
      confirmIsFinal: isFinal
    })
  }

  onConfirm = (...args) => {
    this.onConfirmAction(...args)
    this.closeConfirmModal()
  }

  closeConfirmModal = () => {
    this.onConfirmAction = () => {}

    this.setState({
      confirmModalVisible: false,
      confirmSubject: '',
      confirmPreview: ''
    })
  }

  render() {
    const { client } = this.props
    const {
      confirmModalVisible,
      confirmPreview,
      confirmSubject,
      confirmIsFinal
    } = this.state

    return (
      <ApolloProvider client={client}>
        <>
          <ConfirmModalContext.Provider value={this.confirmWithModal}>
            <BrowserRouter>
              <>
                <Header />
                <Router />
              </>
            </BrowserRouter>
          </ConfirmModalContext.Provider>
          <ConfirmModal
            visible={confirmModalVisible}
            onConfirm={this.onConfirm}
            onClose={this.closeConfirmModal}
            subject={confirmSubject}
            preview={confirmPreview}
            final={confirmIsFinal}
          />
        </>
      </ApolloProvider>
    )
  }
}

export default Root

import * as React from 'react'

import { Query } from '../apollo/Query'
import settingsQuery from '../queries/settingsQuery'

const withSettingsQuery = (WrappedComponent) => ({ ...rest }) => (
  <Query
    query={settingsQuery}
    showWhileLoading={true}
    component={({
      queryResult: { settings },
      queryError,
      queryLoading,
      refetch
    }) => (
      <WrappedComponent
        settings={settings}
        settingsError={queryError}
        settingsLoading={queryLoading}
        refetchSettings={refetch}
        {...rest}
      />
    )}
  />
)

export default withSettingsQuery

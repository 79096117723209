import * as React from 'react'

import adminBannedIpAddressesQuery from '../queries/adminBannedIpAddressesQuery'
import adminUnbanIpAddressMutation from '../queries/adminUnbanIpAddressMutation'
import { Mutation } from '../apollo/Mutation'

const withAdminUnbanIpAddress = (WrappedComponent) => ({ ...rest }) => (
  <Mutation
    mutation={adminUnbanIpAddressMutation}
    refetchQueries={[{ query: adminBannedIpAddressesQuery }]}
    component={({
      mutationResult: { adminUnbanIpAddress },
      mutationError,
      mutationLoading,
      mutator
    }) => (
      <WrappedComponent
        adminUnbanIpAddress={(ipAddress) => mutator({ variables: { ipAddress } })}
        adminUnbanIpAddressResult={adminUnbanIpAddress}
        adminUnbanIpAddressError={mutationError}
        adminUnbanIpAddressLoading={mutationLoading}
        {...rest}
      />
    )}
  />
)

export default withAdminUnbanIpAddress

import * as React from 'react'
import { AnyFunction } from '../types/AnyFunction'
import styled from 'styled-components'
import Modal from '../components/Modal'
import Button from '../components/Button'
import ListHeader from '../components/ListHeader'

const ConfirmItemContent = styled.div`
  margin: 2rem auto;
  text-align: center;
  max-width: 40rem;
`

const ConfirmButton = styled(Button)`
  background: var(--red);
  border-color: var(--red);
  margin-bottom: 1rem;
  color: white;

  &:hover {
    border-color: var(--black);
  }
`

const CancelButton = styled(Button)`
  color: var(--red);
  border-color: transparent;
`

export const ConfirmModalContext = React.createContext<AnyFunction>(() => {
  console.warn('No modal opener set!')
})

type ModalProps = {
  visible: boolean
  onClose: AnyFunction
  onConfirm: AnyFunction
  subject: string
  preview: React.ReactNode
  final: boolean
}

export const ConfirmModal = ({
  visible = false,
  onClose,
  onConfirm,
  subject,
  preview,
  final = true
}: ModalProps) => (
  <Modal visible={visible} onClose={onClose}>
    <ListHeader>Haluatko varmasti {subject}</ListHeader>
    <ConfirmItemContent>{preview}</ConfirmItemContent>
    {final && (
      <ConfirmItemContent>
        Tätä toimintoa ei voida myöhemmin peruuttaa.
      </ConfirmItemContent>
    )}
    <ConfirmButton onClick={onConfirm}>Vahvista</ConfirmButton>
    <CancelButton onClick={onClose}>Peruuta</CancelButton>
  </Modal>
)

import * as React from 'react'
import * as map from 'lodash/map'
import * as get from 'lodash/get'
import { AnyFunction } from '../types/AnyFunction'
import styled from 'styled-components'
import Button from './Button'
import posed, { PoseGroup } from 'react-pose'

const ListContent = styled(PoseGroup)`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`

const Actions = styled.div`
  padding: 1.5rem 1.111rem 1rem;
`

const ListItem = styled(
  posed.div({
    enter: { opacity: 1 },
    exit: { opacity: 0 }
  })
)`
  &:nth-child(even) {
    background: var(--silver);
  }
`

type Props = {
  data: any[]
  fetchMore?: AnyFunction
  fetchMoreLabel?: string
  renderItem?: AnyFunction
}

class NodeList extends React.Component<Props, any> {
  render() {
    const {
      data,
      renderItem,
      fetchMore,
      fetchMoreLabel = 'Lataa lisää'
    } = this.props

    return (
      <div>
        <ListContent>
          {map(data, (item, idx) => (
            <ListItem
              key={`${item.node.targetType}_${get(
                item,
                'node.id',
                get(item, 'node.identifier', idx)
              )}`}>
              {renderItem(item.node, idx)}
            </ListItem>
          ))}
        </ListContent>
        {fetchMore && (
          <Actions>
            <Button onClick={() => fetchMore(5)}>{fetchMoreLabel}</Button>
          </Actions>
        )}
      </div>
    )
  }
}

export default NodeList

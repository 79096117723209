import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Route } from 'react-router'
import ForceLogout from '../helpers/ForceLogout'

@inject('state')
@observer
class ProtectedRoute extends React.Component<any, any> {
  render() {
    const { state, component: Component, ...rest } = this.props
    const { isLoggedIn } = state

    return (
      <Route
        {...rest}
        render={(props) => {
          return isLoggedIn ? <Component {...props} /> : <ForceLogout />
        }}
      />
    )
  }
}

export default ProtectedRoute

import FontAwesome from '@fortawesome/react-fontawesome'
import { faSkull, faSpinner } from '@fortawesome/fontawesome-free-solid'
import * as React from 'react'
import styled from 'styled-components'

import { ConfirmModal } from '../helpers/ConfirmModal'
import IconSpinner from './IconSpinner'
import Button from './Button'
import withAdminBanIpAddress from './withAdminBanIpAddress'

type Props = {
  disabled?: boolean
  adminBanIpAddress: Function
  adminBanIpAddressLoading: boolean
  ipAddress: string
}

type State = {
  modalVisible: boolean
}

const LeftAlign = styled.div`
  text-align: left;
`

class IpBanButton extends React.Component<Props, State> {
  state = {
    modalVisible: false
  }

  confirm = () => {
    const { ipAddress, adminBanIpAddress } = this.props
    adminBanIpAddress(ipAddress)
    this.setState({ modalVisible: false })
  }

  render() {
    const { disabled, ipAddress, adminBanIpAddressLoading } = this.props
    const { modalVisible } = this.state

    return (
      <>
        <Button
          disabled={disabled || adminBanIpAddressLoading || !ipAddress}
          onClick={() => {
            this.setState({ modalVisible: true })
          }}>
          {adminBanIpAddressLoading ? (
            <IconSpinner>
              <FontAwesome icon={faSpinner} />
            </IconSpinner>
          ) : (
            <FontAwesome icon={faSkull} />
          )}
          Bannaa IP-osoite
        </Button>
        <ConfirmModal
          subject="bannata IP-osoitteen"
          preview={
            <>
              Olet lisäämässä bannia IP-osoitteelle <b>{ipAddress}</b>. Huomaathan:
              <LeftAlign>
                <ul>
                  <li>
                    Käyttäjällä ei välttämättä ole enää sama IP-osoite, joten banni
                    ei välttämättä vaikuta häiritsevään käyttäjään.
                  </li>
                  <li>
                    Muilla käyttäjillä voi olla sama IP-osoite, joten banni voi
                    vaikuttaa myös muihin käyttäjiin.
                  </li>
                  <li>
                    Otathan ylös tämän IP-osoitteen ja syyn miksi bannaat osoitteen,
                    jotta banni voidaan myöhemmin poistaa.
                  </li>
                  <li>
                    Poistathan bannin heti kun mahdollista, jotta esto ei vaikuttaisi
                    moneen käyttäjään.
                  </li>
                </ul>
              </LeftAlign>
            </>
          }
          final={false}
          visible={modalVisible}
          onConfirm={this.confirm}
          onClose={() => {
            this.setState({ modalVisible: false })
          }}
        />
      </>
    )
  }
}

export default withAdminBanIpAddress(IpBanButton)

import FontAwesome from '@fortawesome/react-fontawesome'
import { faUserAltSlash, faSpinner } from '@fortawesome/fontawesome-free-solid'
import * as React from 'react'
import styled from 'styled-components'

import { ConfirmModal } from '../helpers/ConfirmModal'
import IconSpinner from './IconSpinner'
import Button from './Button'
import withAdminDeleteProfile from './withAdminDeleteProfile'

type Props = {
  disabled?: boolean
  adminDeleteProfile: Function
  adminDeleteProfileLoading: boolean
  adminDeleteProfileResult?: {
    success: boolean
  }
  profileUsername: string
  profileId: string
}

type State = {
  modalVisible: boolean
}

const LeftAlign = styled.div`
  text-align: left;
`

const { REACT_APP_SITE } = process.env

class DeleteProfileButton extends React.Component<Props, State> {
  state = {
    modalVisible: false
  }

  confirm = () => {
    const { profileId, adminDeleteProfile } = this.props
    adminDeleteProfile(profileId)
    this.setState({ modalVisible: false })
  }

  componentDidUpdate() {
    const { adminDeleteProfileResult } = this.props
    if (!adminDeleteProfileResult) {
      return
    }
    if (adminDeleteProfileResult.success) {
      window.location.reload()
    }
  }

  render() {
    const {
      disabled,
      profileUsername,
      profileId,
      adminDeleteProfileLoading
    } = this.props
    const { modalVisible } = this.state

    return (
      <>
        <Button
          disabled={disabled || adminDeleteProfileLoading || !profileId}
          onClick={() => {
            this.setState({ modalVisible: true })
          }}>
          {adminDeleteProfileLoading ? (
            <IconSpinner>
              <FontAwesome icon={faSpinner} />
            </IconSpinner>
          ) : (
            <FontAwesome icon={faUserAltSlash} />
          )}
          Poista {REACT_APP_SITE}profiili
        </Button>
        <ConfirmModal
          subject={`poistaa ${REACT_APP_SITE}profiilin`}
          preview={
            <>
              Olet poistamassa {REACT_APP_SITE}profiilia <b>{profileUsername}</b>.
              Huomaathan:
              <LeftAlign>
                <ul>
                  <li>
                    Kun poistat käyttäjän Demi-profiilin hän ei voi enää käyttää tai
                    palauttaa sitä.
                  </li>
                  <li>
                    Käyttäjän nimimerkki näkyy poiston jälkeen muille käyttäjille
                    muodossa "Poistunut demittäjä".
                  </li>
                  <li>
                    Käyttäjä voi luoda uuden Demi-profiilin samalle tunnukselle.
                  </li>
                </ul>
              </LeftAlign>
            </>
          }
          final={false}
          visible={modalVisible}
          onConfirm={this.confirm}
          onClose={() => {
            this.setState({ modalVisible: false })
          }}
        />
      </>
    )
  }
}

export default withAdminDeleteProfile(DeleteProfileButton)

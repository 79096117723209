import gql from 'graphql-tag'

export const PageInfoFieldsFragment = gql`
  fragment pageInfoFields on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`

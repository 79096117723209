const cache = {}

export const optimism = (storageKey: string) => {
  if (!(storageKey in cache)) {
    cache[storageKey] = hydrate()
  }

  function get() {
    return cache[storageKey]
  }

  function set(value) {
    cache[storageKey] = value
    return true
  }

  function remove() {
    cache[storageKey] = undefined
    return false
  }

  function persist() {
    if (typeof cache[storageKey] !== 'undefined') {
      sessionStorage.setItem(
        'optimism_' + storageKey,
        JSON.stringify(cache[storageKey])
      )
    }
  }

  function hydrate() {
    const storedValue = sessionStorage.getItem('optimism_' + storageKey)
    return storedValue ? JSON.parse(storedValue) : undefined
  }

  return {
    get,
    set,
    remove,
    persist,
    key: storageKey
  }
}

import * as React from 'react'
import styled from 'styled-components'

const Select = styled.select`
  border: solid 2px #ccc;
  background: rgba(255, 255, 255, 0.7);
  padding: 0.5rem 0.65rem;

  &[disabled] {
    background: none;
    color: var(--black);
  }
`

export default function(props) {
  return <Select {...props} />
}

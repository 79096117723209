import * as flowRight from 'lodash/flowRight'
import styled from 'styled-components'
import * as React from 'react'

import Button from '../components/Button'
import { ConfirmModalContext } from '../helpers/ConfirmModal'
import ErrorDetails from '../components/ErrorDetails'
import LoadingSpinner from '../components/LoadingSpinner'
import profileQuery from '../queries/profileQuery'
import ProfileBioAdministration from '../components/ProfileBioAdministration'
import ProfileImageAdministration from '../components/ProfileImageAdministration'
import Select from '../forms/Select'
import { query } from '../apollo/Query'
import UserInfo from '../components/UserInfo'
import withPartners, { Props as withPartnersProps } from '../components/withPartners'
import withUser, { Props as withUserProps } from '../components/withUser'
import withAdminUpdateDemiProfile, {
  Props as withAdminUpdateDemiProfileProps
} from '../components/withAdminUpdateDemiProfile'

const SITE = process.env.REACT_APP_SITE

type Props = withPartnersProps &
  withUserProps &
  withAdminUpdateDemiProfileProps & {
    username?: string
    onClose?: Function
    queryResult?: {
      profile: any
    }
    queryError?: Error
    refetch?: Function
  }

type State = {
  selectedPartnerId: string
}

const SectionDiv = styled.div`
  margin-bottom: 30px;
`

const ActionButtonsDiv = styled.div`
  margin-top: 30px;
`

const ActionButton = styled(Button)`
  margin-top: 5px;
`

class UserDetails extends React.Component<Props, State> {
  state = {
    selectedPartnerId: ''
  }

  setSelectedPartnerFromProps(props: Props, prevProps?: Props) {
    // Do not update if already updated once
    if (
      (prevProps && prevProps.user) ||
      !props.user ||
      !!this.state.selectedPartnerId
    ) {
      return null
    }
    const currentPartnerId =
      (props.user &&
        props.user.demiProfile &&
        props.user.demiProfile.partner &&
        props.user.demiProfile.partner.id) ||
      undefined
    this.setState({
      selectedPartnerId: currentPartnerId || ''
    })
  }

  componentDidUpdate(prevProps) {
    this.setSelectedPartnerFromProps(this.props, prevProps)
  }

  componentDidMount() {
    this.setSelectedPartnerFromProps(this.props)
  }

  render() {
    const {
      username,
      queryResult: { profile },
      queryError,
      refetch: refetchProfile,
      partners,
      partnersError,
      partnersLoading,
      user,
      userError,
      userLoading,
      refetchUser,
      adminUpdateDemiProfile,
      adminUpdateDemiProfileError,
      adminUpdateDemiProfileLoading
    } = this.props
    const { selectedPartnerId } = this.state

    const renderPage = (content) => (
      <div>
        <h1>{username}</h1>
        {content}
      </div>
    )

    if (queryError) {
      return renderPage(<ErrorDetails error={queryError} />)
    }

    if (!profile) {
      return renderPage(<h2>Haetaan tietoja...</h2>)
    }

    const currentPartnerId =
      (user &&
        user.demiProfile &&
        user.demiProfile.partner &&
        user.demiProfile.partner.id) ||
      undefined
    const partnerChanged = (selectedPartnerId || undefined) !== currentPartnerId

    return renderPage(
      <ConfirmModalContext.Consumer>
        {(confirmWithModal) => (
          <div>
            {profile.userIdentifier && (
              <SectionDiv>
                <h2>Käyttäjän tiedot</h2>
                <UserInfo userId={profile.userIdentifier} />
              </SectionDiv>
            )}
            <SectionDiv>
              <h2>Profiilikuva</h2>
              <ProfileImageAdministration
                profile={profile}
                confirmWithModal={confirmWithModal}
                onCompleted={refetchProfile}
              />
            </SectionDiv>
            <SectionDiv>
              <h2>Kuvaus</h2>
              <ProfileBioAdministration
                profile={profile}
                confirmWithModal={confirmWithModal}
                onCompleted={refetchProfile}
              />
            </SectionDiv>
            {SITE === 'demi' && (
              <SectionDiv>
                <h2>Partneri</h2>
                {partnersError || userError || adminUpdateDemiProfileError ? (
                  <ErrorDetails error={partnersError} />
                ) : partnersLoading ||
                userLoading ||
                adminUpdateDemiProfileLoading ? (
                  <LoadingSpinner>Ladataan...</LoadingSpinner>
                ) : partners && user ? (
                  <>
                    <Select
                      value={selectedPartnerId}
                      onChange={({ target: { value } }) => {
                        this.setState({ selectedPartnerId: value })
                      }}>
                      <option value="">Ei partneria</option>
                      {partners.map((partner) => (
                        <option key={partner.id} value={partner.id}>
                          {partner.name}
                        </option>
                      ))}
                    </Select>
                    {partnerChanged && (
                      <ActionButtonsDiv>
                        <ActionButton
                          onClick={() => {
                            this.setState({
                              selectedPartnerId: currentPartnerId || ''
                            })
                          }}>
                          Peruuta
                        </ActionButton>
                        <ActionButton
                          onClick={() => {
                            adminUpdateDemiProfile(user.demiProfile.id, {
                              partnerId: selectedPartnerId || null
                            }).then(refetchUser)
                          }}>
                          Tallenna
                        </ActionButton>
                      </ActionButtonsDiv>
                    )}
                  </>
                ) : (
                  <LoadingSpinner>Ladataan...</LoadingSpinner>
                )}
              </SectionDiv>
            )}
          </div>
        )}
      </ConfirmModalContext.Consumer>
    )
  }
}

export default flowRight(
  withProfileWithUsernameFromRoute,
  withUserWithIdFromProfile,
  withPartners,
  withAdminUpdateDemiProfile
)(UserDetails)

function withUserWithIdFromProfile(WrappedComponent: React.ReactNode) {
  return (props) => {
    const userId =
      (props.queryResult &&
        props.queryResult.profile &&
        props.queryResult.profile.userIdentifier) ||
      undefined
    return withUser(WrappedComponent)({ userId, ...props })
  }
}

function withProfileWithUsernameFromRoute(WrappedComponent: React.ReactNode) {
  return (props) => {
    const username = props.match.params.username
    if (!username) {
      return null
    }
    return query(profileQuery)(WrappedComponent)({
      showWhileLoading: true,
      variables: { username },
      ...props
    })
  }
}

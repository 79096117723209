export default function parseRaw(body: string) {
  const slateState = convertRawStringToSlateState(body)
  return serialize(slateState)
}

const convertRawStringToSlateState = (rawString: string) => {
  const slateState = JSON.parse(rawString)

  return slateState.document
    ? slateState
    : {
        document: slateState,
        kind: 'state'
      }
}

function serialize(state) {
  if (!state.document.nodes) {
    return ''
  }

  return state.document.nodes
    .reduce((nodes, node) => {
      return nodes.concat(serializeNode(node).join(''))
    }, [])
    .join('\n')
}

function serializeNode(node) {
  node = untersify(node)

  if (node.kind === 'text') {
    return node.ranges.map((range) => range.text).join('')
  }

  return node.nodes.map(serializeNode)
}

// Helper functions to convert tersified slate state serializations to
// untersified for easier handling.

function untersify(object) {
  switch (object.kind) {
    case 'text':
      return untersifyText(object)
    case 'block':
      return untersifyBlock(object)
    case 'inline':
      return untersifyInline(object)
    default:
      console.log('Unhandled kind: ' + object.kind)
      return object
  }
}

function untersifyBlock(object) {
  if (object.isVoid || !object.nodes || !object.nodes.length) {
    return {
      key: object.key,
      data: object.data,
      kind: object.kind,
      type: object.type,
      isVoid: object.isVoid,
      nodes: [
        {
          kind: 'text',
          text: ''
        }
      ]
    }
  }

  return object
}

function untersifyInline(object) {
  if (object.isVoid || !object.nodes || !object.nodes.length) {
    return {
      key: object.key,
      data: object.data,
      kind: object.kind,
      type: object.type,
      isVoid: object.isVoid,
      nodes: [
        {
          kind: 'text',
          text: ''
        }
      ]
    }
  }

  return object
}

function untersifyRange(object) {
  return {
    kind: 'range',
    text: object.text,
    marks: object.marks || []
  }
}

function untersifyText(object) {
  if (object.ranges) {
    return object
  }

  return {
    key: object.key,
    kind: object.kind,
    ranges: [
      {
        text: object.text,
        marks: object.marks || []
      }
    ]
  }
}

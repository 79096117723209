import * as React from 'react'
import styled from 'styled-components'
import styledTs from 'styled-components-ts'
import { AnyFunction } from '../types/AnyFunction'
import is from 'styled-is'
import FontAwesome from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid'

const ToggleButton = styledTs<{ state: string; right?: boolean }>(styled.button)`
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  outline: 0;
  cursor: pointer;
  text-align: center;
  width: 1rem;
  heigth: 1rem;
  
  ${is('right')`
    margin-left: auto;
    position: absolute;
    top: 1.7rem;
    right: 1rem;
  `}
`

export default ({
  onClick,
  state,
  className,
  right = false
}: {
  onClick: AnyFunction
  state: 'open' | 'closed'
  className?: string
  right?: boolean
}) => (
  <ToggleButton
    right={right}
    className={className}
    type="button"
    onClick={onClick}
    state={state}>
    <FontAwesome icon={state === 'open' ? faMinus : faPlus} />
  </ToggleButton>
)

import * as React from 'react'
import styled from 'styled-components'

const ListHeading = styled.h2`
  margin-top: 2rem;
  text-transform: uppercase;
  text-align: center;
`

export default ({
  children,
  heading = children
}: {
  children?: any
  heading?: string
}) => <ListHeading>{heading}</ListHeading>

import gql from 'graphql-tag'

export default ((site) => gql`
  query user($id: String!) {
    user(id: $id) {
      id
      email
      phoneNumber
      signupIpAddress
      demiProfile {
        id
        username
        ${
          site === 'demi'
            ? `
        partner {
          id
          name
        }
        `
            : ''
        }
      }
      lilyProfile {
        id
        username
      }
      groups {
        id
        name
      }
    }
  }
`)(process.env.REACT_APP_SITE)

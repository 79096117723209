import * as React from 'react'

import DeleteProfileButton from './DeleteProfileButton'
import withProfile from './withProfile'

type Props = {
  disabled?: boolean
  username?: string
  profile?: {
    id: string
    username: string
  }
  profileLoading: boolean
}

const UserDeleteProfileButton = ({
  disabled,
  profile,
  profileLoading,
  ...rest
}: Props) => {
  return (
    <DeleteProfileButton
      disabled={disabled || profileLoading}
      profileId={profile ? profile.id : undefined}
      profileUsername={profile ? profile.username : undefined}
      {...rest}
    />
  )
}

export default withProfile(UserDeleteProfileButton)

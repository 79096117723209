import * as React from 'react'
import { observer } from 'mobx-react'
import LoginWidget from '../components/LoginWidget'

export default observer(() => (
  <div>
    <h1>Kirjaudu</h1>
    <LoginWidget />
  </div>
))

import * as React from 'react'
import styled from 'styled-components'

type Props = {
  error: Error
}
type State = {
  detailsVisible: boolean
}

const ErrorPre = styled.pre`
  padding: 10px;
  white-space: pre-wrap;
  background-color: #333333;
  color: #ffffff;
  font-size: 14px;
  cursor: default;
`

const LinkA = styled.a`
  text-decoration: underline;
  cursor: pointer;
`

export default class ErrorDetails extends React.Component<Props, State> {
  state = {
    detailsVisible: false
  }
  render() {
    const { error } = this.props
    const { detailsVisible } = this.state

    if (!error) {
      return null
    }

    return (
      <div>
        {detailsVisible ? (
          <>
            Jotain meni vikaan.
            <ErrorPre>{JSON.stringify(error, undefined, 2)}</ErrorPre>
          </>
        ) : (
          <>
            <LinkA
              onClick={() => {
                this.setState({ detailsVisible: true })
              }}>
              Jotain
            </LinkA>{' '}
            meni vikaan
          </>
        )}
      </div>
    )
  }
}

import gql from 'graphql-tag'

export const banUser = gql`
  mutation banUser($identifier: ID!, $banDuration: DurationType!) {
    banDemiUser(demiProfileId: $identifier, banDuration: $banDuration) {
      success
      errorCode
      errorMessage
    }
  }
`

export const unbanUser = gql`
  mutation unbanDemiUser($identifier: ID!) {
    unbanDemiUser(demiProfileId: $identifier) {
      success
      errorCode
      errorMessage
    }
  }
`

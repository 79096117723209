import * as React from 'react'

import { Query } from '../apollo/Query'
import adminBannedIpAddressesQuery from '../queries/adminBannedIpAddressesQuery'

const withAdminBannedIpAddressesQuery = (WrappedComponent) => ({ ...rest }) => (
  <Query
    query={adminBannedIpAddressesQuery}
    showWhileLoading={true}
    component={({
      queryResult: { adminBannedIpAddresses },
      queryError,
      queryLoading,
      refetch
    }) => (
      <WrappedComponent
        adminBannedIpAddresses={adminBannedIpAddresses}
        adminBannedIpAddressesError={queryError}
        adminBannedIpAddressesLoading={queryLoading}
        refetchAdminBannedIpAddresses={refetch}
        {...rest}
      />
    )}
  />
)

export default withAdminBannedIpAddressesQuery

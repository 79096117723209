import * as React from 'react'
import { query } from '../apollo/Query'
import LogoutLink from './LogoutLink'
import viewerQuery from '../queries/viewerQuery'
import * as get from 'lodash/get'
import styled from 'styled-components'
import { compose } from 'react-apollo'
import { observer } from 'mobx-react'

const UserIndicator = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const Avatar = styled.div`
  flex: 1 0 30px;
  width: 30px;
  height: 30px;
  align-self: center;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: center;

  @media (min-width: 620px) {
    flex: 1 0 50px;
    width: 50px;
    height: 50px;
  }
`

const enhance = compose(query(viewerQuery), observer)

const profileImageProp = ((site) => {
  switch (site) {
    case 'demi':
      return 'demi.profileImage'
    case 'lily':
      return 'lilyProfile.profileImage'
    default:
      return null
  }
})(process.env.REACT_APP_SITE)

export default enhance(({ queryResult: { viewer } }) => {
  if (viewer) {
    const profileImg = get(viewer, profileImageProp)

    return (
      <UserIndicator>
        <Avatar style={{ backgroundImage: `url("${profileImg}")` }} />
        <LogoutLink />
      </UserIndicator>
    )
  } else {
    return null
  }
})

import * as React from 'react'
import styled, { keyframes } from 'styled-components'

const rotateAnim = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const IconSpinner = styled.span`
  & > * {
    animation: ${rotateAnim} 2s linear infinite;
  }
`

export default IconSpinner

import * as React from 'react'

import adminUpdateDemiProfileMutation from '../queries/adminUpdateDemiProfileMutation'
import { Mutation } from '../apollo/Mutation'

export type Props = {
  adminUpdateDemiProfile: Function
  adminUpdateDemiProfileResult?: {
    id: string
  }
  adminUpdateDemiProfileError?: Error
  adminUpdateDemiProfileLoading: boolean
}

const withAdminUpdateDemiProfile = (WrappedComponent) => (rest) => (
  <Mutation
    mutation={adminUpdateDemiProfileMutation}
    component={({
      mutationResult: { adminUpdateDemiProfile },
      mutationError,
      mutationLoading,
      mutator
    }) => {
      const props: Props = {
        adminUpdateDemiProfile: (id, profile) =>
          mutator({ variables: { id, profile } }),
        adminUpdateDemiProfileResult: adminUpdateDemiProfile,
        adminUpdateDemiProfileError: mutationError,
        adminUpdateDemiProfileLoading: mutationLoading
      }
      return <WrappedComponent {...props} {...rest} />
    }}
  />
)

export default withAdminUpdateDemiProfile

import gql from 'graphql-tag'
import { DemiProfileFieldsFragment } from './ProfileFields'

export const ReportFieldsFragment = gql`
  fragment ReportFields on Report {
    type
    id
    targetType
    numDuplicates
    message
    reason
    createdAt
    reporter {
      demiProfile {
        ...DemiProfileFragment
      }
    }
  }
  ${DemiProfileFieldsFragment}
`

import * as React from 'react'
import { Redirect } from 'react-router'
import { routes } from '../routes'
import { clearAuthTokens } from './authentication'

class ForceLogout extends React.Component {
  componentWillMount() {
    clearAuthTokens()
  }

  render() {
    return <Redirect to={routes.login()} />
  }
}

export default ForceLogout
